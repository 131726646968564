import React, { Component } from 'react'
import { Route, Switch } from 'react-router' // react-router v4/v5
import indexRoutes from './routes/index'

import { ConnectedRouter } from 'connected-react-router'
import { connect } from 'react-redux'

import { motorService } from './services/motor.service'
import _ from 'lodash'

class App extends Component {
  getBrandCar = async () => {
    const {
      carBrandLoading,
      carBrandDone,
      carModelDone,
      carModelLoading,
    } = this.props
    try {
      carBrandLoading()
      carModelLoading()
      const responseV = await motorService.getBrandCarV()
      const dataV = _.get(responseV, 'data.string', [])
      const responseModelV = await motorService.getModelCarV()
      const dataModelV = _.get(responseModelV, 'data', [])
      const { updateCarBrands, updateCarModels } = this.props
      updateCarBrands(dataV, [])
      updateCarModels(dataModelV, [])
      carBrandDone()
      carModelDone()
    } catch (error) {
      carBrandDone()
      carModelDone()
      const response = _.get(error, 'response', {})
      const responseStatus = response.status
      if (
        responseStatus === 500 ||
        responseStatus === 403 ||
        responseStatus === 401 ||
        responseStatus === 400
      ) {
        const {
          history: { push },
        } = this.props
        push('/error-system')
        window.scrollTo(0, 0)
        return
      }
    }
  }

  componentDidMount = async () => {
    this.getBrandCar()
  }

  render = () => {
    const { props } = this
    return (
      <ConnectedRouter history={props.history}>
        <Switch>
          {indexRoutes.map((prop, key) => (
            <Route
              path={prop.path}
              component={prop.component}
              key={key}
              exact={prop.exact}
            />
          ))}
        </Switch>
      </ConnectedRouter>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    CMILoading: () => {
      dispatch({
        type: 'API_PACKAGE_CMI_LOADING',
      })
    },
    CMIDone: () => {
      dispatch({
        type: 'API_PACKAGE_CMI_DONE',
      })
    },
    updateCMI: data => {
      dispatch({
        type: 'PACKAGES_CMI',
        data,
      })
    },
    carBrandLoading: () => {
      dispatch({
        type: 'API_CAR_BRAND_LOADING',
      })
    },
    carBrandDone: () => {
      dispatch({
        type: 'API_CAR_BRAND_DONE',
      })
    },
    updateCarBrands: (dataV, dataC) => {
      dispatch({
        type: 'CAR_BRANDS',
        dataV,
        dataC,
      })
    },
    carModelLoading: () => {
      dispatch({
        type: 'API_CAR_MODEL_LOADING',
      })
    },
    carModelDone: () => {
      dispatch({
        type: 'API_CAR_MODEL_DONE',
      })
    },
    updateCarModels: (dataV, dataC) => {
      dispatch({
        type: 'CAR_MODELS',
        dataV,
        dataC,
      })
    },
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
