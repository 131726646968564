const amplifyConfig = {
  API: {
    endpoints: [
      {
        name: 'v1',
        endpoint: process.env.REACT_APP_AMP_API_ENDPOINT,
        region: process.env.REACT_APP_AMP_COG_REGION
      }
    ]
  }
};

export default amplifyConfig;
