import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Wrapper from '../../components/Wrapper'
import Header from '../../components/Header'
import Body from '../../components/Body'
import TopBar from '../../components/TopBar'

import _ from 'lodash'
import { motorService } from '../../services/motor.service'
import moment from 'moment'

import Modal from '@material-ui/core/Modal'

const fontDefault = {
  fontFamily: 'thonburiregular',
  fontStyle: 'normal',
}

const styles = {
  textTopBar: {
    ...fontDefault,
    color: '#333333',
    fontSize: '18px',
    fontWeight: 'bold',
  },
  container: {
    backgroundColor: '#eff0f4',
  },
  list: {
    margin: '0',
    padding: '0',
    listStyle: 'none',
  },
  listItem: {
    paddingLeft: '16px',
  },
  listItemContent: {
    borderBottom: 'solid 1px #dee1e9',
    padding: '16px 0',
  },
  listItemText: {
    color: '#0e162c',
    ...fontDefault,
    fontFamily: 'San Francisco Text',
    fontSize: '16px',
  },
  fieldInput: {
    width: '100%',
    padding: '16px',
    ...fontDefault,
    fontSize: '16px',
    color: '#333333',
    borderRadius: '8px',
    border: 'none',
    '&:focus': {
      outlineWidth: '0',
    },
    '&::placeholder': {
      color: '#cdd2de',
    },
  },
  inputContainerInput: {
    border: '1px solid #ffffff',
    margin: '16px',
    borderRadius: '8px',
    boxShadow: '0 1px 4px 0 rgba(157, 157, 157, 0.3)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}

class YearPicker extends Component {
  state = {
    filterText: '',
  }

  backButton = () => {
    const { onBack = () => {} } = this.props
    onBack()
    this.goToTop()
  }

  goToTop = () => {
    const presentation = document.querySelector('[role="presentation"]')
    if (presentation) {
      presentation.scrollTop = 0
    }
  }

  goNext = year => () => {
    const { currentProduct, onBack = () => {}, setUserInput } = this.props
    setUserInput({
      carYear: year,
    })
    this.getPackage(year)
    onBack()
    this.goToTop()
  }

  getPackage = async year => {
    const { packageLoading, packageDone, carModel, auth, carBrand } = this.props
    const token = _.get(auth, 'token', null)
    try {
      packageLoading()

      const response = await motorService.getPackages(token, {
        car_code: _.get(carModel, 'value', ''),
        car_type: _.get(carModel, 'carType', ''),
        car_year: year,
        eff_date: moment().format('DD/MM/YYYY'),
        policy_types: ['1', '3', '3p', '2p'],
        car_brand: carBrand,
      })
      const { updatePackages } = this.props
      updatePackages(_.get(response, 'data', []))
      packageDone()
    } catch (error) {
      //packageDone()
    }
  }

  render() {
    const { classes, open } = this.props
    const { filterText } = this.state
    const years = _.range(
      new Date().getFullYear() - 1,
      new Date().getFullYear() - 14
    )
    const filteredYears = years.filter(p => {
      return String(p).match(filterText)
    })
    return (
      <Modal
        style={{
          outline: 0,
          overflow: 'auto',
          height: '100%',
          display: 'block',
        }}
        open={open}
      >
        <Wrapper>
          <Header>
            <TopBar arrowLeft={this.backButton}>
              <Typography align="center" className={classes.textTopBar}>
                เลือกปีที่ผลิต
              </Typography>
            </TopBar>
          </Header>
          <Body style={{ backgroundColor: '#eff0f4', overflow: 'auto' }}>
            <div className={classes.container}>
              <Grid container>
                <Grid item xs={12}>
                  <div className={classes.inputContainerInput}>
                    <input
                      className={classes.fieldInput}
                      type="text"
                      placeholder="ค้นหา"
                      autoFocus
                      value={filterText}
                      onChange={event => {
                        this.setState({
                          filterText: event.target.value,
                        })
                      }}
                    />
                  </div>
                  <ul className={classes.list}>
                    {filteredYears
                      .sort((a, b) => b - a)
                      .map((value, index) => {
                        return (
                          <li
                            className={classes.listItem}
                            onClick={this.goNext(value)}
                            key={value}
                          >
                            <div className={classes.listItemContent}>
                              <span className={classes.listItemText}>
                                {value}
                              </span>
                            </div>
                          </li>
                        )
                      })}
                  </ul>
                </Grid>
              </Grid>
            </div>
          </Body>
        </Wrapper>
      </Modal>
    )
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    currentProduct: 'V',
    carModel: _.get(state, 'motor.userInput.carModel', {}),
    carBrand: _.get(state, 'motor.userInput.carBrand', {}),
    auth: state.auth,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    packageLoading: () => {
      dispatch({
        type: 'API_PACKAGE_LOADING',
      })
    },
    packageDone: () => {
      dispatch({
        type: 'API_PACKAGE_DONE',
      })
    },
    updatePackages: data => {
      dispatch({
        type: 'PACKAGES',
        data,
      })
    },
    setUserInput: data => {
      dispatch({
        type: 'USER_INPUT',
        data,
      })
    },
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(YearPicker))
