import SelectCarModel from '../views/SelectCarModel';
import SelectPackage from '../views/SelectPackage';

const motorRoutes = [
  {
    path: '/select-car-model',
    name: 'Select Car Model',
    component: SelectCarModel
  },
  {
    path: '/select-package',
    name: 'Select package',
    component: SelectPackage
  },
  { redirect: true, path: '/', pathTo: '/select-car-model', name: 'SelectCarModel' }
];

export default motorRoutes;
