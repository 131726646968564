import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'

const fontDefault = {
  fontFamily: 'thonburiregular',
  fontStyle: 'normal',
}

const styles = {
  fixed: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    paddingTop: '0px',
    backgroundColor: 'white',
    display: 'block',
  },
  wrapper: {
    backgroundColor: 'white',
    boxShadow: 'none',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
  },
  flexItem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexItemLeft: {
    marginLeft: '15px',
    width: '30%',
  },
  flexItemRight: {
    textAlign: 'right',
    marginRight: '15px',
    width: '30%',
  },
}

const TopBar = props => {
  const { classes, children, arrowLeft, close, insufficientFund } = props
  return (
    <div className={classes.fixed}>
      <div className={classes.wrapper}>
        {arrowLeft ? (
          <div className={classes.flexItemLeft} onClick={arrowLeft}>
            <IconButton>
              <Icon style={{ color: '#4b5161', fontSize: '15px' }}>
                arrow_back_ios
              </Icon>
            </IconButton>
          </div>
        ) : (
          <div className={classes.flexItemLeft} />
        )}
        <div className={classes.flexItem}>{children}</div>
        {close ? (
          <div className={classes.flexItemRight} onClick={close}>
            <IconButton>
              <Icon
                style={{
                  color: '#4b5161',
                  fontSize: '19px',
                  fontWeight: 'bold',
                }}
              >
                close
              </Icon>
            </IconButton>
          </div>
        ) : (
          <div className={classes.flexItemRight} />
        )}
      </div>
      {insufficientFund && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: '#ffa340',
            position: 'relative',
            padding: '16px',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <span
              style={{
                ...fontDefault,
                fontSize: '16px',
                color: 'white',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Icon
                style={{
                  color: 'white',
                  fontSize: '15px',
                  marginRight: '8px',
                }}
              >
                info
              </Icon>{' '}
              กรุณาเติมเงินให้เพียงพอ
            </span>
          </div>
          <a
            href="ascendmoney://wallet.truemoney.co.th/app/660000000004?type=topup"
            style={{
              ...fontDefault,
              fontSize: '12px',
              color: 'white',
              border: '1px solid white',
              background: 'transparent',
              borderRadius: '50px',
              textDecoration: 'none',
              padding: '3px 9px',
            }}
          >
            เติมเงิน
          </a>
        </div>
      )}
    </div>
  )
}

export default withStyles(styles)(TopBar)
