import React, { Component } from 'react';
import motorRoutes from '../../routes/motor';
import { Route, Switch, Redirect } from 'react-router';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import appStyle from './style';
import CssBaseline from '@material-ui/core/CssBaseline';
import './style.css';

class MotorLayout extends Component {
  render() {
    return (
      <div>
        <CssBaseline />
        <Switch>
          {motorRoutes.map((prop, key) => {
            if (prop.redirect) {
              return <Redirect from={prop.path} to={prop.pathTo} key={key} />
            }
            return (
              <Route
                key={key}
                path={prop.path}
                component={prop.component}
                exact
              />
            )
          })}
        </Switch>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(appStyle)(MotorLayout))
