import _ from 'lodash'

const defaultState = {
  firstname_th: '',
  firstname_en: '',
  lastname_en: '',
  lastname_th: '',
  mobile: '',
  balance: '',
  //token: '',
}

export default function motorReducer(state = defaultState, action = {}) {
  let data
  switch (action.type) {
    case 'CALLBACK':
      data = _.get(action, 'data', {})
      return {
        ...state,
        ...data,
      }
    case 'ME':
      data = _.get(action, 'data', {})
      return {
        ...state,
        ...data,
      }
    default:
      return state
  }
}
