import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import motorReducer from './motor.reducer'
import authReducer from './auth.reducer'
import systemReducer from './system.reducer'
export default history =>
  combineReducers({
    router: connectRouter(history),
    motor: motorReducer,
    auth: authReducer,
    systems: systemReducer,
  })
