import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  wrapper: {
    flexGrow: 1,
    marginTop: '50px',
  },
}

const Body = props => {
  const { classes, children, style = {} } = props
  return (
    <div className={classes.wrapper} style={style}>
      {children}
    </div>
  )
}

export default withStyles(styles)(Body)
