import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  wrapperDisabled: {
    display: "block",
    backgroundColor: "#B0B0B0",
    borderRadius: "30px",
    color: "white",
    padding: "8px",
    textAlign: "center",
    margin: "15px",
    fontFamily: "thonburiregular",
    fontStyle: "normal",
    fontSize: "18px"
  },
  wrapper: {
    display: "block",
    backgroundColor: "#ff8300",
    borderRadius: "30px",
    color: "white",
    padding: "8px",
    textAlign: "center",
    cursor: "pointer",
    margin: "15px",
    fontFamily: "thonburiregular",
    fontStyle: "normal",
    fontSize: "18px"
  }
};

const SubmitButton = props => {
  const { classes, children, disabled, ...otherProps } = props;
  return (
    <div
      className={disabled ? classes.wrapperDisabled : classes.wrapper}
      {...otherProps}
    >
      {children}
    </div>
  );
};

export default withStyles(styles)(SubmitButton);
