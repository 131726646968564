import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  wrapper: {
    flexShrink: 0
  }
};

const Footer = props => {
  const { classes, children, style = {} } = props;
  return (
    <div className={classes.wrapper} style={style}>
      {children}
    </div>
  );
};

export default withStyles(styles)(Footer);
