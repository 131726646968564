import { API } from 'aws-amplify'
import axios from 'axios'

const getBrandCarV = () => {
  const url = process.env.REACT_APP_S3_PUBLIC
  return axios.get(`${url}/v1/car_brand_v.json`)
}

const getBrandCarC = () => {
  const url = process.env.REACT_APP_S3_PUBLIC
  return axios.get(`${url}/v1/car_brand_c.json`)
}

const getModelCarV = () => {
  const url = process.env.REACT_APP_S3_PUBLIC
  return axios.get(`${url}/v1/car_model_v.json`)
}

const getModelCarC = () => {
  const url = process.env.REACT_APP_S3_PUBLIC
  return axios.get(`${url}/v1/car_model_c.json`)
}

const getPackages = (token, data = {}) => {
  const stringvalue = data.policy_types.map(v => {
    return `"${v}"`
  })
  return API.get(
    'v1',
    `/vmi_packages?car_code=${data.car_code}&car_year=${
      data.car_year
    }&eff_date=${data.eff_date}&policy_types=[${stringvalue}]&car_type=${
      data.car_type
    }&car_brand=${data.car_brand}`,
    {
      headers: {
        Authorization: token,
      },
    }
  )
}

const getInitial = token => {
  return API.get('v1', '/initial', {
    headers: {
      Authorization: token,
    },
  })
}

const getPackagesCMI = (token, data = {}) => {
  return API.get('v1', '/cmi_packages', {
    headers: {
      Authorization: token,
    },
  })
}

const postOrder = (token, data = {}) => {
  return API.post('v1', '/order', {
    body: data,
    headers: {
      Authorization: token,
    },
  })
}

const postUW = (token, data = {}) => {
  return API.post('v1', '/uw_issues', {
    body: data,
    headers: {
      Authorization: token,
    },
  })
}

const getOTP = (token, data = {}) => {
  return API.get('v1', '/otp', {
    headers: {
      Authorization: token,
    },
  })
}

const postPaymentVMI = (token, order_id, data = {}) => {
  return API.post('v1', `/payment_vmi/${order_id}`, {
    body: data,
    headers: {
      Authorization: token,
    },
  })
}

const postPaymentCMI = (token, order_id, data = {}) => {
  return API.post('v1', `/payment_cmi/${order_id}`, {
    body: data,
    headers: {
      Authorization: token,
    },
  })
}

export const motorService = {
  getBrandCarV,
  getBrandCarC,
  getModelCarV,
  getModelCarC,
  getPackages,
  postOrder,
  postUW,
  getOTP,
  postPaymentVMI,
  postPaymentCMI,
  getPackagesCMI,
  getInitial,
}
