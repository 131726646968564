import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import CircularProgress from '@material-ui/core/CircularProgress'

const ColorCircularProgress = withStyles({
  root: {
    color: '#ff8300',
  },
})(CircularProgress)

const styles = {
  progress: {
    position: 'absolute',
    top: 'calc(50% - 20px)',
    left: 'calc(50% - 20px)',
  },
}

const Loading = props => {
  const { classes } = props
  return (
    <ColorCircularProgress
      size={40}
      thickness={5}
      className={classes.progress}
    />
  )
}

export default withStyles(styles)(Loading)
