import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'

import Wrapper from '../../components/Wrapper'
import Body from '../../components/Body'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import TopBar from '../../components/TopBar'
import SubmitButton from '../../components/SubmitButton'
import { motorActions } from '../../actions'
import { motorService } from '../../services/motor.service'

import BackgroundImage from '../../assets/motor/background.png'
import Icon from '@material-ui/core/Icon'
import _ from 'lodash'

import BrandPicker from './../../components/BrandPicker'
import ModelPicker from './../../components/ModelPicker'
import YearPicker from './../../components/YearPicker'

import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import moment from 'moment'
import Hidden from '@material-ui/core/Hidden'

const fontDefault = {
  fontFamily: 'thonburiregular',
  fontStyle: 'normal',
}

const styles = {
  inputContainerInput: {
    border: '1px solid #ffffff',
    marginTop: '7px',
    borderRadius: '8px',
    boxShadow: '0 1px 4px 0 rgba(157, 157, 157, 0.3)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  inputContainerInputDisabled: {
    border: '1px solid #ffffff',
    marginTop: '7px',
    borderRadius: '8px',
    boxShadow: '0 1px 4px 0 rgba(157, 157, 157, 0.3)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#EFEFEF',
  },

  textTopBar: {
    ...fontDefault,
    color: '#333333',
    fontSize: '18px',
    fontWeight: 'bold',
  },
  backgroundImageContainer: {
    position: 'absolute',
    height: '160px',
    width: '100%',
  },
  backgroundImage: {
    height: '160px',
    objectFit: 'cover',
    width: '100%',
  },
  card: {
    borderRadius: '8px',
    boxShadow: '0 2px 4px 0 rgba(94, 92, 92, 0.3)',
    backgroundImage: 'linear-gradient(162deg, #ffffff, #ffffff)',
    margin: '16px',
  },
  cardHeader: {
    backgroundColor: '#ddedfe',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    padding: '16px',
  },
  cardTitle: {
    color: '#118df5',
    fontSize: '18px',
    ...fontDefault,
  },
  cardContent: {
    padding: '16px',
  },
  container: {
    marginTop: '10px',
    position: 'relative',
  },
  fieldTitle: {
    ...fontDefault,
    fontSize: '16px',
    color: '#0e162c',
  },
  inputContainer: {
    marginTop: '7px',
    padding: '16px',
    borderRadius: '8px',
    boxShadow: '0 1px 4px 0 rgba(157, 157, 157, 0.3)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  inputContainerDisable: {
    marginTop: '7px',
    padding: '16px',
    borderRadius: '8px',
    boxShadow: '0 1px 4px 0 rgba(157, 157, 157, 0.3)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#EFEFEF',
  },
  fieldValueBlank: {
    color: '#cdd2de',
  },
  fieldValue: {
    color: '#0e162c',
  },
}

class SelectCarModel extends Component {
  state = {
    showBrandPicker: false,
    showModelPicker: false,
    showYearPicker: false,
  }

  backButton = () => {
    window.location.replace('https://www.truemoney.com/insurance/')
  }

  goToNextPage = async () => {
    const {
      history: { push },
    } = this.props

    push('/select-package')
    window.scrollTo(0, 0)
  }

  openBrandPicker = () => {
    this.setState({
      showBrandPicker: true,
    })
  }

  closeBrandPicker = () => {
    this.setState({
      showBrandPicker: false,
    })
  }

  openModelPicker = () => {
    this.setState({
      showModelPicker: true,
    })
  }

  closeModelPicker = () => {
    this.setState({
      showModelPicker: false,
    })
  }

  openYearPicker = () => {
    this.setState({
      showYearPicker: true,
    })
  }

  closeYearPicker = () => {
    this.setState({
      showYearPicker: false,
    })
  }

  setDesktopBrand = (event, brand) => {
    const { setUserInput, onBack = () => {} } = this.props
    setUserInput({
      carBrand: brand,
      carModel: null,
      carYear: null,
    })
  }

  getModelCar = async brand => {
    const {
      cmiCarType,
      currentProduct,
      carModelLoading,
      carModelDone,
      auth,
    } = this.props
    const token = _.get(auth, 'token', null)
    try {
      carModelLoading()
      const allPromise = []
      const carFirst = motorService
        .getModelCar(token, {
          brand,
          prod_type: currentProduct,
          car_type: '1',
        })
        .then(respCars => {
          let cars = _.get(respCars, 'model_cars', [])
          cars.forEach(car => {
            car.carType = 1
          })
          return cars
        })
      const carThird = motorService
        .getModelCar(token, {
          brand,
          prod_type: currentProduct,
          car_type: '3',
        })
        .then(respCars => {
          let cars = _.get(respCars, 'model_cars', [])
          cars.forEach(car => {
            car.carType = 3
          })
          return cars
        })
      allPromise.push(carFirst, carThird)
      let data = await Promise.all(allPromise)
      const models = []
      data = _.concat(_.get(data, 0, []), _.get(data, 1, []))
      data.forEach(model => {
        models.push({
          value: _.get(model, 'x_car_code', ''),
          label: _.get(model, 'x_car_model', ''),
          x_cc: _.get(model, 'x_cc', ''),
          carType: _.get(model, 'carType', 0),
        })
      })

      const { updateCarModels } = this.props
      updateCarModels(models)
      carModelDone()
    } catch (error) {
      //carModelDone()
    }
  }

  setDesktopModel = (event, value) => {
    const { onBack = () => {}, setUserInput } = this.props
    setUserInput({
      carModel: value,
      carYear: null,
    })
  }

  setDesktopYear = (event, year) => {
    const { currentProduct, onBack = () => {}, setUserInput } = this.props
    setUserInput({
      carYear: year,
    })
    if (currentProduct === 'V') {
      this.getPackage(year)
    }
  }

  getPackage = async year => {
    const { packageLoading, packageDone, carModel, auth, carBrand } = this.props
    const token = _.get(auth, 'token', null)
    try {
      packageLoading()

      const response = await motorService.getPackages(token, {
        car_code: _.get(carModel, 'value', ''),
        car_type: _.get(carModel, 'carType', ''),
        car_year: year,
        eff_date: moment().format('DD/MM/YYYY'),
        policy_types: ['1', '3', '3p', '2p'],
        car_brand: carBrand,
      })
      const { updatePackages } = this.props
      updatePackages(_.get(response, 'data', []))
      packageDone()
    } catch (error) {
      //packageDone()
    }
  }

  render() {
    const {
      classes,
      carBrand,
      carModel,
      carYear,
      carBrandsV,
      isCarBrandLoading,
      carModelsV,
      isCarModelLoading,
    } = this.props
    const { showBrandPicker, showModelPicker, showYearPicker } = this.state
    let carModels = []
    carModels = _.get(carModelsV, carBrand, [])
      .filter((thing, index, self) => {
        return index === self.findIndex(t => t.x_carname === thing.x_carname)
      })
      .map(model => {
        return {
          value: _.get(model, 'x_car_code', ''),
          label: _.get(model, 'x_carname', ''),
          x_cc: _.get(model, 'x_cc', ''),
          carType: _.get(model, 'x_cartype', 0),
        }
      })
    const { filterText } = this.state
    const filteredCarModels = carModels.sort((a, b) => {
      if (_.get(a, 'label', '') < _.get(b, 'label', '')) {
        return -1
      }
      if (_.get(a, 'label', '') > _.get(b, 'label', '')) {
        return 1
      }
      return 0
    })
    const years = _.range(
      new Date().getFullYear() - 1,
      new Date().getFullYear() - 14
    )
    return (
      <Wrapper>
        <Body style={{ backgroundColor: '#eff0f4' }}>
          <div className={classes.backgroundImageContainer}>
            <img
              alt="background_image"
              className={classes.backgroundImage}
              src={BackgroundImage}
            />
          </div>
          <Container maxWidth="sm">
            <div className={classes.container}>
              <Grid container>
                <Grid item xs={12}>
                  <div className={classes.card}>
                    <div className={classes.cardHeader}>
                      <span className={classes.cardTitle}>
                        ข้อมูลเกี่ยวกับรถ
                      </span>
                    </div>
                    <Hidden smDown>
                      <div className={classes.cardContent}>
                        <div className={classes.fieldContainer}>
                          <span className={classes.fieldTitle}>
                            เลือกยี่ห้อรถยนต์
                          </span>
                          <Autocomplete
                            value={carBrand}
                            options={carBrandsV}
                            getOptionLabel={option => option}
                            onChange={this.setDesktopBrand}
                            loading={isCarBrandLoading}
                            loadingText="กำลังโหลด"
                            renderInput={params => (
                              <TextField
                                {...params}
                                className={classes.inputContainerInput}
                                variant="outlined"
                                placeholder="เลือก"
                                fullWidth
                              />
                            )}
                          />
                        </div>
                        <br />
                        <div className={classes.fieldContainer}>
                          <span className={classes.fieldTitle}>
                            เลือกรุ่นรถยนต์
                          </span>
                          <Autocomplete
                            value={carModel}
                            options={carModels}
                            getOptionLabel={option => option.label}
                            onChange={this.setDesktopModel}
                            loading={isCarModelLoading}
                            loadingText="กำลังโหลด"
                            disableOpenOnFocus={!carBrand}
                            renderInput={params => (
                              <TextField
                                {...params}
                                className={
                                  !carBrand
                                    ? classes.inputContainerInputDisabled
                                    : classes.inputContainerInput
                                }
                                variant="outlined"
                                placeholder="เลือก"
                                fullWidth
                              />
                            )}
                          />
                        </div>
                        <br />
                        <div className={classes.fieldContainer}>
                          <span className={classes.fieldTitle}>
                            เลือกปีที่จดทะเบียน
                          </span>
                          <Autocomplete
                            value={carYear}
                            options={years}
                            getOptionLabel={option => option}
                            onChange={this.setDesktopYear}
                            disableOpenOnFocus={!(carBrand && carModel)}
                            renderInput={params => (
                              <TextField
                                {...params}
                                className={
                                  !(carBrand && carModel)
                                    ? classes.inputContainerInputDisabled
                                    : classes.inputContainerInput
                                }
                                variant="outlined"
                                placeholder="เลือก"
                                fullWidth
                              />
                            )}
                          />
                        </div>
                        <br />
                      </div>
                    </Hidden>
                    <Hidden mdUp>
                      <div className={classes.cardContent}>
                        <div className={classes.fieldContainer}>
                          <span className={classes.fieldTitle}>
                            เลือกยี่ห้อรถยนต์
                          </span>
                          <div
                            className={classes.inputContainer}
                            onClick={this.openBrandPicker}
                          >
                            <span
                              className={
                                carBrand
                                  ? classes.fieldValue
                                  : classes.fieldValueBlank
                              }
                            >
                              {carBrand ? carBrand : 'เลือก'}
                            </span>
                            <Icon
                              style={{ color: '#4b5161', fontSize: '12px' }}
                            >
                              arrow_forward_ios
                            </Icon>
                          </div>
                        </div>
                        <br />
                        <div
                          className={classes.fieldContainer}
                          onClick={() => {
                            if (carBrand) {
                              this.openModelPicker()
                            }
                          }}
                        >
                          <span className={classes.fieldTitle}>
                            เลือกรุ่นรถยนต์
                          </span>
                          <div
                            className={
                              carBrand
                                ? classes.inputContainer
                                : classes.inputContainerDisable
                            }
                          >
                            <span
                              className={
                                carModel
                                  ? classes.fieldValue
                                  : classes.fieldValueBlank
                              }
                            >
                              {carModel
                                ? _.get(carModel, 'label', '')
                                : 'เลือก'}
                            </span>
                            <Icon
                              style={{ color: '#4b5161', fontSize: '12px' }}
                            >
                              arrow_forward_ios
                            </Icon>
                          </div>
                        </div>
                        <br />
                        <div
                          className={classes.fieldContainer}
                          onClick={() => {
                            if (carBrand && carModel) {
                              this.openYearPicker()
                            }
                          }}
                        >
                          <span className={classes.fieldTitle}>
                            เลือกปีที่จดทะเบียน
                          </span>
                          <div
                            className={
                              carBrand && carModel
                                ? classes.inputContainer
                                : classes.inputContainerDisable
                            }
                          >
                            <span
                              className={
                                carYear
                                  ? classes.fieldValue
                                  : classes.fieldValueBlank
                              }
                            >
                              {carYear ? carYear : 'เลือก'}
                            </span>
                            <Icon
                              style={{ color: '#4b5161', fontSize: '12px' }}
                            >
                              arrow_forward_ios
                            </Icon>
                          </div>
                        </div>
                        <br />
                      </div>
                    </Hidden>
                  </div>
                </Grid>
              </Grid>
            </div>
            <BrandPicker
              open={showBrandPicker}
              onBack={this.closeBrandPicker}
              history={this.props.history}
            />
            <ModelPicker
              open={showModelPicker}
              onBack={this.closeModelPicker}
              history={this.props.history}
            />
            <YearPicker
              open={showYearPicker}
              onBack={this.closeYearPicker}
              history={this.props.history}
            />
          </Container>
        </Body>
        <Footer style={{ backgroundColor: '#eff0f4' }}>
          <Container maxWidth="sm">
            <SubmitButton
              onClick={() => {
                if (carBrand && carModel && carYear) {
                  this.goToNextPage()
                }
              }}
            >
              เช็คราคา
            </SubmitButton>
          </Container>
        </Footer>
        <Header>
          <TopBar arrowLeft={this.backButton}>
            <Typography align="center" className={classes.textTopBar}>
              เลือกประเภท
            </Typography>
          </TopBar>
        </Header>
      </Wrapper>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    carBrand: _.get(state, 'motor.userInput.carBrand', ''),
    carModel: _.get(state, 'motor.userInput.carModel', ''),
    carYear: _.get(state, 'motor.userInput.carYear', ''),

    currentProduct: 'V',
    cmiCarType: _.get(state, 'motor.userInput.cmiCarType', 0),
    carBrandsV: _.get(state, 'motor.carBrandsV', []),
    carBrandsC: _.get(state, 'motor.carBrandsC', []),
    isCarBrandLoading: _.get(state, 'motor.isCarBrandLoading', false),
    auth: state.auth,

    carModelsV: _.get(state, 'motor.carModelsV', {}),
    isCarModelLoading: _.get(state, 'motor.isCarModelLoading', false),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setPackage: (data, policyTypes, registerYear) =>
      dispatch(motorActions.setPackage(data, policyTypes, registerYear)),
    carModelLoading: () => {
      dispatch({
        type: 'API_CAR_MODEL_LOADING',
      })
    },
    carModelDone: () => {
      dispatch({
        type: 'API_CAR_MODEL_DONE',
      })
    },
    updateCarModels: data => {
      dispatch({
        type: 'CAR_MODELS',
        data,
      })
    },
    setUserInput: data => {
      dispatch({
        type: 'USER_INPUT',
        data,
      })
    },
    packageLoading: () => {
      dispatch({
        type: 'API_PACKAGE_LOADING',
      })
    },
    packageDone: () => {
      dispatch({
        type: 'API_PACKAGE_DONE',
      })
    },
    updatePackages: data => {
      dispatch({
        type: 'PACKAGES',
        data,
      })
    },
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SelectCarModel))
