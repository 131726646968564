import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  wrapper: {
    flexShrink: 0
  }
};

const Header = props => {
  const { classes, children } = props;
  return <div className={classes.wrapper}>{children}</div>;
};

export default withStyles(styles)(Header);
