import _ from 'lodash'

const defaultState = {}

export default function systemReducer(state = defaultState, action = {}) {
  let data
  switch (action.type) {
    case 'INIT':
      data = _.get(action, 'data', [])
      return {
        ...state,
        ...data,
      }
    default:
      return state
  }
}
