import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Wrapper from '../../components/Wrapper'
import Header from '../../components/Header'
import Body from '../../components/Body'
import TopBar from '../../components/TopBar'
import SubmitButton from '../../components/SubmitButton'
import Slider from '@material-ui/core/Slider'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Container from '@material-ui/core/Container'
import Modal from '@material-ui/core/Modal'
import _ from 'lodash'

import BackgroundImage from '../../assets/motor/background.png'
import Checkbox from '@material-ui/core/Checkbox'
import Loading from '../../components/Loading'
import numeral from 'numeral'

const fontDefault = {
  fontFamily: 'thonburiregular',
  fontStyle: 'normal',
}

const styles = {
  textTopBar: {
    ...fontDefault,
    color: '#333333',
    fontSize: '18px',
    fontWeight: 'bold',
  },
  backgroundImageContainer: {
    position: 'absolute',
    height: '160px',
    width: '100%',
  },
  backgroundImage: {
    height: '160px',
    objectFit: 'cover',
    width: '100%',
  },
  card: {
    borderRadius: '8px',
    boxShadow: '0 2px 4px 0 rgba(94, 92, 92, 0.3)',
    backgroundImage: 'linear-gradient(162deg, #ffffff, #ffffff)',
    margin: '16px',
  },
  container: {
    marginTop: '10px',
    position: 'relative',
  },
  pageTitle: {
    ...fontDefault,
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'white',
    display: 'block',
    marginLeft: '16px',
  },
  pageTitleDetail: {
    ...fontDefault,
    fontFamily: 'San Francisco Text',
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'white',
    display: 'block',
    marginBottom: '10px',
    marginLeft: '16px',
    marginRight: '16px',
  },
  cardHeader: {
    textAlign: 'center',
    overflow: 'hidden',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },
  cardTitle: {
    ...fontDefault,
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: '19px',
    color: '#0e162c',
    marginBottom: '0px',
  },
  cardPrice: {
    ...fontDefault,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'San Francisco Text',
    fontSize: '15.6px',
    fontWeight: 'bold',
    color: '#3f3f3f',
    marginTop: '0px',
    marginBottom: '0px',
  },
  cardPriceNumber: {
    ...fontDefault,
    marginLeft: '8px',
    fontFamily: 'San Francisco Text',
    fontSize: '44.6px',
    fontWeight: 'bold',
    color: '#3f3f3f',
    marginTop: '0px',
    marginBottom: '0px',
  },
  cardReturnContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  cardReturn: {
    backgroundColor: '#1a63b6',
    borderRadius: '20px',
    padding: '6px',
    paddingLeft: '18px',
    paddingRight: '18px',
  },
  cardReturnText: {
    ...fontDefault,
    color: 'white',
    fontWeight: 'bold',
    fontSize: '12px',
  },
  cardReturnTextNumber: {
    ...fontDefault,
    color: 'white',
    fontFamily: 'San Francisco Text',
    fontWeight: 'bold',
    fontSize: '18px',
  },
  cardContainer: {
    margin: '0 16px',
    marginBottom: '16px',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow:
      '0 0 4px 0 rgba(35, 57, 120, 0.14), 0 4px 6px 0 rgba(35, 57, 120, 0.1)',
  },
  slideTitle: {
    color: '#0e162c',
    ...fontDefault,
    fontSize: '16px',
    marginTop: '4px',
  },
  cardContent: {
    margin: '15px',
    overFlow: 'auto',
  },
  cardContentItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '2px',
    marginBottom: '2px',
  },
  cardContentItemText: {
    ...fontDefault,
    color: '#333333',
    fontSize: '16px',
  },
  cardContentItemTextNumber: {
    ...fontDefault,
    color: '#333333',
    fontFamily: 'San Francisco Text',
    fontSize: '16px',
    textAlign: 'right',
  },
  line: {
    borderTop: '1px solid #E9E9E9',
    borderBottom: 'none',
  },
  expandButton: {
    marginLeft: '15px',
    marginRight: '15px',
    marginBottom: '15px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  expandText: {
    ...fontDefault,
    color: '#118DF5',
    fontSize: '14px',
  },
  expandIcon: {
    color: '#118DF5',
    fontSize: '20px',
  },

  title: {
    ...fontDefault,
    fontSize: '20px',
    color: '#4A4A4A',
    marginLeft: '13px',
    fontWeight: 500,
  },
  titleContainer: {
    marginTop: '29px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  packageFilterContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '15px',
  },
  packageFilterDetail: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  packageFilterDetailText: {
    ...fontDefault,
    fontSize: '14px',
    color: '#878B96',
    marginRight: '4px',
  },
  packageFilterPrice: {
    ...fontDefault,
    fontSize: '14px',
    color: '#878B96',
  },
  sliderContainer: {
    marginTop: '10px',
    marginLeft: '10px',
    marginRight: '10px',
  },
  carousel: {
    paddingLeft: '15px',
    paddingRight: '15px',
  },
  cardContentContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  expandContentContainer: {
    marginLeft: '15px',
    marginRight: '15px',
    marginBottom: '15px',
  },
  expandTitle: {
    ...fontDefault,
    color: '#4B5161',
    fontWeight: 'bold',
    fontSize: '14px',
    marginTop: '0px',
    marginBottom: '0px',
  },
  expandItem: {
    alignItems: 'center',
  },
  expandContentText: {
    ...fontDefault,
    color: '#878B96',
    fontSize: '14px',
  },
  expandContentTextDark: {
    ...fontDefault,
    color: '#4B5161',
    fontSize: '14px',
    display: 'block',
  },
  cardContentItemTextContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    cursor: 'pointer',
    width: '18px',
    height: '18px',
    marginLeft: '6px',
  },
  CMITitle: {
    marginTop: '8px',
    ...fontDefault,
    color: '#0e162c',
    fontSize: '16px',
    display: 'block',
    textAlign: 'center',
  },
  CMIDesc: {
    ...fontDefault,
    color: '#878b96',
    fontSize: '16px',
    display: 'block',
    textAlign: 'center',
  },

  modalContainer: {
    overflow: 'auto',
    outline: 0,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    width: '90%',
    maxHeight: '80%',
    backgroundColor: 'white',
    borderRadius: '8px',
  },
  modalContent: {
    overflowY: 'auto',
    padding: '15px',
  },
  modalClose: {
    position: 'absolute',
    right: '0px',
    top: '0px',
  },
  typographyTitle: {
    ...fontDefault,
    fontSize: '20px',
    color: '#4A4A4A',
    fontWeight: 500,
  },
  modalTitle: {
    ...fontDefault,
    fontSize: '14px',
    color: '#4B5161',
    textAlign: 'center',
    marginTop: '5px',
    marginBottom: '15px',
  },
  filterButtonText: {
    ...fontDefault,
    fontSize: '16px',
    color: '#C3C5CB',
  },
  filterButton: {
    padding: '11px',
    width: '100%',
    borderRadius: '8px',
    border: '1px solid #C3C5CB',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginLeft: '3px',
    marginRight: '3px',
    '&:first-of-type': {
      marginLeft: '0px',
    },
    '&:last-of-type': {
      marginRight: '0px',
    },
  },
  filterButtonTextActive: {
    ...fontDefault,
    fontSize: '16px',
    color: '#F78E02',
  },
  filterButtonActive: {
    padding: '11px',
    width: '100%',
    borderRadius: '8px',
    border: '1px solid #F78E02',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginLeft: '3px',
    marginRight: '3px',
    '&:first-of-type': {
      marginLeft: '0px',
    },
    '&:last-of-type': {
      marginRight: '0px',
    },
  },
  filterButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '15px',
  },
}

const CustomSlider = withStyles({
  root: {
    color: '#C4C4C4',
    height: '3px',
    padding: '13px 0',
  },
  thumb: {
    height: '20px',
    width: '20px',
    marginTop: '-10px',
    marginLeft: '-10px',
    backgroundColor: '#118df5',
  },
  track: {
    height: '1px',
    backgroundColor: '#118df5',
  },
  rail: {
    color: '#C4C4C4',
    opacity: 1,
    height: '1px',
  },
  valueLabel: {
    top: '-26px',
    left: 'calc(-50% + -13px)',
    border: '1px solid #118df5',
    paddingLeft: '16px',
    paddingRight: '16px',
    borderRadius: '20px',
    '& *': {
      background: 'transparent',
      color: '#0e162c',
      fontFamily: 'San Francisco Text',
      fontWeight: 'bold',
      fontSize: '14px',
    },
  },
})(Slider)

const CustomCheckbox = withStyles({
  checked: {
    color: '#1a63b6',
  },
})(Checkbox)

class SelectPackage extends Component {
  state = {
    minFilterPriceOneThree: 100000,
    maxFilterPriceOneThree: 1000000,
    filterPriceOneThree: 200000,
    currentOneThreePackage: {},

    minFilterPriceOneFive: 100000,
    maxFilterPriceOneFive: 1000000,
    filterPriceOneFive: 200000,
    currentOneFivePackage: {},

    minFilterPriceOne: 100000,
    maxFilterPriceOne: 1000000,
    filterPriceOne: 200000,
    isExpandOne: false,
    isAddActOne: false,
    currentOnePackage: {},

    filterDiscDeductOne: 5000,
    maxFilterDiscDeduct: 5000,
    minFilterDiscDeduct: 3000,

    minFilterPrice: 100000,
    maxFilterPrice: 1000000,
    filterPrice: 200000,
    isAddAct: false,
    isExpand: false,
    isAddActThird: false,
    isExpandThird: false,
    modalVisible: false,
    data: [],
    twoPlusPackages: [],
    threePackages: [],
    currentTwoplusPackage: {},
    currentThreePackage: {},
    isPageLoading: true,
    CMIexpand: {},
  }

  componentDidMount = () => {
    this.initial()
  }

  copyDataToCurrentOne = () => {
    const { filterDiscDeductOne } = this.state
    if (filterDiscDeductOne === 3000) {
      const {
        minFilterPriceOneThree,
        maxFilterPriceOneThree,
        filterPriceOneThree,
        currentOneThreePackage,

        minFilterPriceOne,
        maxFilterPriceOne,
        filterPriceOne,
        currentOnePackage,
      } = this.state
      this.setState({
        minFilterPriceOne: minFilterPriceOneThree,
        maxFilterPriceOne: maxFilterPriceOneThree,
        filterPriceOne: filterPriceOneThree,
        currentOnePackage: currentOneThreePackage,
      })
    } else if (filterDiscDeductOne === 5000) {
      const {
        minFilterPriceOneFive,
        maxFilterPriceOneFive,
        filterPriceOneFive,
        currentOneFivePackage,

        minFilterPriceOne,
        maxFilterPriceOne,
        filterPriceOne,
        currentOnePackage,
      } = this.state
      this.setState({
        minFilterPriceOne: minFilterPriceOneFive,
        maxFilterPriceOne: maxFilterPriceOneFive,
        filterPriceOne: filterPriceOneFive,
        currentOnePackage: currentOneFivePackage,
      })
    }
  }

  filterOneThree = (price, oneThreePackages = []) => {
    if (oneThreePackages.length === 0) {
      oneThreePackages = _.get(this.state, 'oneThreePackages', [])
    }
    if (oneThreePackages.length > 0) {
      let targets = oneThreePackages.filter(pack => {
        return parseInt(pack.sum_ins) === parseInt(price)
      })
      if (targets.length === 0) {
        const minPricePack = _.minBy(oneThreePackages, pack => {
          return _.get(pack, 'sum_ins', 0)
        })
        if (!!minPricePack) {
          const minPrice = minPricePack.sum_ins
          targets = oneThreePackages.filter(pack => {
            return parseInt(pack.sum_ins) === parseInt(minPrice)
          })
          if (targets.length > 0) {
            this.setState({
              filterPriceOneThree: minPrice,
              filterPriceOne: minPrice,
            })
          }
        }
      }
      const cheapest = _.minBy(targets, pack => {
        return _.get(pack, 'total_prem_vol', 999999)
      })
      if (cheapest) {
        this.setState(
          {
            currentOneThreePackage: cheapest,
            currentOnePackage: cheapest,
          },
          () => {
            this.copyDataToCurrentOne()
          }
        )
      }
    }
  }

  filterOneFive = (price, oneFivePackages = []) => {
    if (oneFivePackages.length === 0) {
      oneFivePackages = _.get(this.state, 'oneFivePackages', [])
    }
    if (oneFivePackages.length > 0) {
      let targets = oneFivePackages.filter(pack => {
        return parseInt(pack.sum_ins) === parseInt(price)
      })
      if (targets.length === 0) {
        const minPricePack = _.minBy(oneFivePackages, pack => {
          return _.get(pack, 'sum_ins', 0)
        })
        if (!!minPricePack) {
          const minPrice = minPricePack.sum_ins
          targets = oneFivePackages.filter(pack => {
            return parseInt(pack.sum_ins) === parseInt(minPrice)
          })
          if (targets.length > 0) {
            this.setState({
              filterPriceOneFive: minPrice,
              filterPriceOne: minPrice,
            })
          }
        }
      }
      const cheapest = _.minBy(targets, pack => {
        return _.get(pack, 'total_prem_vol', 999999)
      })
      if (cheapest) {
        this.setState(
          {
            currentOneFivePackage: cheapest,
            currentOnePackage: cheapest,
          },
          () => {
            this.copyDataToCurrentOne()
          }
        )
      }
    }
  }

  filterTwo = (price, twoPlusPackages = []) => {
    if (twoPlusPackages.length === 0) {
      twoPlusPackages = _.get(this.state, 'twoPlusPackages', [])
    }
    if (twoPlusPackages.length > 0) {
      let targets = twoPlusPackages.filter(pack => {
        return parseInt(pack.sum_ins) === parseInt(price)
      })
      if (targets.length === 0) {
        const minPricePack = _.minBy(twoPlusPackages, pack => {
          return _.get(pack, 'sum_ins', 0)
        })
        if (!!minPricePack) {
          const minPrice = minPricePack.sum_ins
          targets = twoPlusPackages.filter(pack => {
            return parseInt(pack.sum_ins) === parseInt(minPrice)
          })
          if (targets.length > 0) {
            this.setState({
              filterPrice: minPrice,
            })
          }
        }
      }
      const cheapest = _.minBy(targets, pack => {
        return _.get(pack, 'total_prem_vol', 999999)
      })
      if (cheapest) {
        this.setState({
          currentTwoplusPackage: cheapest,
        })
      }
    }
  }

  filterThree = (threePackages = []) => {
    if (threePackages.length === 0) {
      threePackages = _.get(this.state, 'threePackages', [])
    }
    if (threePackages.length > 0) {
      const cheapest = _.minBy(threePackages, pack => {
        return _.get(pack, 'total_prem_vol', 999999)
      })
      if (cheapest) {
        this.setState({
          currentThreePackage: cheapest,
        })
      }
    }
  }

  changeSlideOneThree = (event, value) => {
    this.filterOneThree(value)
    this.setState({
      filterPriceOneThree: value,
      filterPriceOne: value,
    })
  }

  changeSlideOneFive = (event, value) => {
    this.filterOneFive(value)
    this.setState({
      filterPriceOneFive: value,
      filterPriceOne: value,
    })
  }

  changeSlideOne = (event, value) => {
    const { filterDiscDeductOne } = this.state
    if (filterDiscDeductOne === 3000) {
      this.changeSlideOneThree(event, value)
    } else if (filterDiscDeductOne === 5000) {
      this.changeSlideOneFive(event, value)
    }
  }

  changeSlideOneDeduct = (event, value) => {
    const { filterDiscDeductOne } = this.state
    if (value >= 4000 && value <= 4500 && filterDiscDeductOne === 5000) {
      this.setState(
        {
          filterDiscDeductOne: 3000,
        },
        () => {
          this.copyDataToCurrentOne()
        }
      )
    } else if (value <= 4000 && value >= 3500 && filterDiscDeductOne === 3000) {
      this.setState(
        {
          filterDiscDeductOne: 5000,
        },
        () => {
          this.copyDataToCurrentOne()
        }
      )
    } else {
      if (value === 3500 || value === 4000 || value === 4500) return
      this.setState(
        {
          filterDiscDeductOne: value,
        },
        () => {
          this.copyDataToCurrentOne()
        }
      )
    }
  }

  changeSlide = (event, value) => {
    this.filterTwo(value)
    this.setState({
      filterPrice: value,
    })
  }

  initial = () => {
    const { packages, currentProduct } = this.props
    if (currentProduct === 'V') {
      if (packages.length > 0) {
        const oneThreePackages = packages.filter(pack => {
          const risk = _.get(pack, 'risk', 0)
          const disc_deduct = _.get(pack, 'disc_deduct', 0)
          return (
            (risk === 1 || risk === '1') &&
            (disc_deduct === 3000 || disc_deduct === '3000')
          )
        })
        const oneFivePackages = packages.filter(pack => {
          const risk = _.get(pack, 'risk', 0)
          const disc_deduct = _.get(pack, 'disc_deduct', 0)
          return (
            (risk === 1 || risk === '1') &&
            (disc_deduct === 5000 || disc_deduct === '5000')
          )
        })
        const twoPlusPackages = packages.filter(pack => {
          const risk = _.get(pack, 'risk', 0)
          return risk === 5 || risk === '5'
        })
        const threePackages = packages.filter(pack => {
          const risk = _.get(pack, 'risk', 0)
          return risk === 3 || risk === '3'
        })
        this.setState({
          oneThreePackages,
          oneFivePackages,
          twoPlusPackages,
          threePackages,
        })
        const {
          filterPrice,
          filterPriceOneThree,
          filterPriceOneFive,
        } = this.state
        this.filterOneThree(filterPriceOneThree, oneThreePackages)
        this.filterOneFive(filterPriceOneFive, oneFivePackages)
        this.filterTwo(filterPrice, twoPlusPackages)
        this.filterThree(threePackages)
        let maxValue = _.maxBy(twoPlusPackages, value => {
          return _.get(value, 'sum_ins', 0)
        })
        if (maxValue) {
          if (filterPrice > maxValue.sum_ins) {
            this.setState({
              maxFilterPrice: maxValue.sum_ins,
              filterPrice: maxValue.sum_ins,
            })
          } else {
            this.setState({
              maxFilterPrice: maxValue.sum_ins,
            })
          }
        }
        let minValue = _.minBy(twoPlusPackages, value => {
          return _.get(value, 'sum_ins', 0)
        })
        if (minValue) {
          if (filterPrice < minValue.sum_ins) {
            this.setState({
              minFilterPrice: minValue.sum_ins,
              filterPrice: minValue.sum_ins,
            })
          } else {
            this.setState({
              minFilterPrice: minValue.sum_ins,
            })
          }
        }
        let maxValueOneThree = _.maxBy(oneThreePackages, value => {
          return _.get(value, 'sum_ins', 0)
        })
        if (maxValueOneThree) {
          if (filterPriceOneThree > maxValueOneThree.sum_ins) {
            this.setState({
              maxFilterPriceOneThree: maxValueOneThree.sum_ins,
              filterPriceOneThree: maxValueOneThree.sum_ins,
            })
          } else {
            this.setState({
              maxFilterPriceOneThree: maxValueOneThree.sum_ins,
            })
          }
        }
        let minValueOneThree = _.minBy(oneThreePackages, value => {
          return _.get(value, 'sum_ins', 0)
        })
        if (minValueOneThree) {
          if (filterPriceOneThree < minValueOneThree.sum_ins) {
            this.setState({
              minFilterPriceOneThree: minValueOneThree.sum_ins,
              filterPriceOneThree: minValueOneThree.sum_ins,
            })
          } else {
            this.setState({
              minFilterPriceOneThree: minValueOneThree.sum_ins,
            })
          }
        }

        let maxValueOneFive = _.maxBy(oneFivePackages, value => {
          return _.get(value, 'sum_ins', 0)
        })
        if (maxValueOneFive) {
          if (filterPriceOneFive > maxValueOneFive.sum_ins) {
            this.setState({
              maxFilterPriceOneFive: maxValueOneFive.sum_ins,
              filterPriceOneFive: maxValueOneFive.sum_ins,
            })
          } else {
            this.setState({
              maxFilterPriceOneFive: maxValueOneFive.sum_ins,
            })
          }
        }
        let minValueOneFive = _.minBy(oneFivePackages, value => {
          return _.get(value, 'sum_ins', 0)
        })
        if (minValueOneFive) {
          if (filterPriceOneFive < minValueOneFive.sum_ins) {
            this.setState({
              minFilterPriceOneFive: minValueOneFive.sum_ins,
              filterPriceOneFive: minValueOneFive.sum_ins,
            })
          } else {
            this.setState({
              minFilterPriceOneFive: minValueOneFive.sum_ins,
            })
          }
        }
        this.copyDataToCurrentOne()
        this.setState({
          isPageLoading: false,
        })
      } else {
        this.setState({
          isPageLoading: false,
        })
      }
    } else {
      this.setState({
        isPageLoading: false,
      })
    }
  }

  componentWillReceiveProps = nextProps => {
    if (
      nextProps.packages !== this.props.packages ||
      nextProps.isPackageLoading !== this.props.isPackageLoading
    ) {
      this.initial()
    }
  }

  goToNextPage = () => () => {
    window.location.replace('https://tmn.app.link/dOT4tnXie1')
  }

  backButton = () => {
    const { currentProduct } = this.props
    const {
      history: { push },
    } = this.props
    if (currentProduct === 'C') {
      push('/home')
    } else {
      push('/select-car-model')
    }
  }

  render() {
    const {
      classes,
      isPackageLoading,
      isPackageCMILoading,
      carBrand,
      carModel,
      carYear,
      currentProduct,
      packagesCMI,
    } = this.props
    const {
      filterPriceOneThree,
      minFilterPriceOneThree,
      maxFilterPriceOneThree,
      currentOneThreePackage,

      filterPriceOneFive,
      minFilterPriceOneFive,
      maxFilterPriceOneFive,
      currentOneFivePackage,

      maxFilterDiscDeduct,
      minFilterDiscDeduct,
      filterDiscDeductOne,

      isAddActOne,
      isExpandOne,
      filterPriceOne,
      minFilterPriceOne,
      maxFilterPriceOne,
      currentOnePackage,

      isAddActThird,
      isExpandThird,
      modalVisible,
      isAddAct,
      isExpand,
      filterPrice,
      minFilterPrice,
      maxFilterPrice,
      currentTwoplusPackage,
      currentThreePackage,
      isPageLoading,
      CMIexpand,
    } = this.state
    return (
      <Wrapper>
        <Body style={{ backgroundColor: '#eff0f4' }}>
          <div className={classes.backgroundImageContainer}>
            <img
              alt="background_image"
              className={classes.backgroundImage}
              src={BackgroundImage}
            />
          </div>
          {(currentProduct === 'V' && (isPackageLoading || isPageLoading)) ||
          (currentProduct === 'C' && isPackageCMILoading) ? (
            <Loading />
          ) : (
            <div className={classes.container}>
              <Container maxWidth="sm">
                {currentProduct === 'V' && (
                  <div>
                    <span className={classes.pageTitle}>
                      แผนประกันรถยนต์สำหรับ
                    </span>
                    <span className={classes.pageTitleDetail}>
                      {carBrand} {_.get(carModel, 'label', '')} {carYear}
                    </span>
                  </div>
                )}

                <Grid container>
                  <Grid item xs={12}>
                    {/*
                  //=================================== 
                  // ประกันชั้น 1 5K && 3k
                  //===================================*/}
                    {_.get(currentOnePackage, 'total_prem_vol', null) && (
                      <div className={classes.cardContainer}>
                        <div className={classes.cardHeader}>
                          <p className={classes.cardTitle}>
                            ประกันรถยนต์ชั้น 1
                          </p>
                          <p className={classes.cardPrice}>
                            ฿{' '}
                            <span className={classes.cardPriceNumber}>
                              {numeral(
                                parseFloat(
                                  _.get(
                                    currentOnePackage,
                                    'total_prem_vol',
                                    999999
                                  )
                                ) +
                                  (isAddActOne
                                    ? parseFloat(
                                        _.get(
                                          currentOnePackage,
                                          'total_prem_comp',
                                          0
                                        )
                                      )
                                    : 0) -
                                  parseFloat(
                                    _.get(
                                      currentOnePackage,
                                      'discount_price',
                                      0
                                    )
                                  )
                              ).format('0,0.00')}
                            </span>
                          </p>
                        </div>
                        <div className={classes.cardReturnContainer}>
                          <div className={classes.cardReturn}>
                            <span className={classes.cardReturnText}>
                              รับเงินคืน{' '}
                              <span className={classes.cardReturnTextNumber}>
                                {isAddActOne
                                  ? numeral(
                                      parseFloat(
                                        _.get(
                                          currentOnePackage,
                                          'cashback_value_cmi',
                                          0
                                        )
                                      ) +
                                        parseFloat(
                                          _.get(
                                            currentOnePackage,
                                            'cashback_value',
                                            0
                                          )
                                        )
                                    ).format('0,0.00')
                                  : numeral(
                                      parseFloat(
                                        _.get(
                                          currentOnePackage,
                                          'cashback_value',
                                          0
                                        )
                                      )
                                    ).format('0,0.00')}
                              </span>{' '}
                              บาท
                            </span>
                          </div>
                        </div>

                        <div className={classes.cardContentContainer}>
                          <div className={classes.cardContent}>
                            {false && (
                              <div className={classes.sliderContent}>
                                <span className={classes.slideTitle}>
                                  เลือกทุนประกันภัย
                                </span>
                                <br />
                                <br />
                                <br />
                                <div>
                                  <CustomSlider
                                    styles={{
                                      thumb: {
                                        height: '20px',
                                        width: '20px',
                                        backgroundColor: '#118df5',
                                      },
                                    }}
                                    aria-label="Filter Price"
                                    aria-labelledby="discrete-slider-always"
                                    valueLabelFormat={() => {
                                      return numeral(filterPriceOne).format(
                                        '0,0'
                                      )
                                    }}
                                    valueLabelDisplay="on"
                                    min={minFilterPriceOne}
                                    max={maxFilterPriceOne}
                                    step={100000}
                                    value={filterPriceOne}
                                    onChange={this.changeSlideOne}
                                  />
                                </div>
                              </div>
                            )}
                            <div className={classes.sliderContent}>
                              <span className={classes.slideTitle}>
                                เลือกค่าเสียหายส่วนแรก
                              </span>
                              <br />
                              <br />
                              <br />
                              <div
                                style={{
                                  marginLeft: '28px',
                                  marginRight: '28px',
                                }}
                              >
                                <CustomSlider
                                  styles={{
                                    thumb: {
                                      height: '20px',
                                      width: '20px',
                                      backgroundColor: '#118df5',
                                    },
                                  }}
                                  aria-label="Filter Price"
                                  aria-labelledby="discrete-slider-always"
                                  valueLabelFormat={() => {
                                    return numeral(filterDiscDeductOne).format(
                                      '0,0'
                                    )
                                  }}
                                  valueLabelDisplay="on"
                                  min={minFilterDiscDeduct}
                                  max={maxFilterDiscDeduct}
                                  step={500}
                                  value={filterDiscDeductOne}
                                  onChange={this.changeSlideOneDeduct}
                                />
                              </div>
                            </div>
                            <div className={classes.cardContentItem}>
                              <span className={classes.cardContentItemText}>
                                ทุนประกัน
                              </span>
                              <span className={classes.cardContentItemText}>
                                {parseFloat(
                                  _.get(currentOnePackage, 'sum_ins', 0)
                                ) === 0
                                  ? 0
                                  : numeral(
                                      _.get(currentOnePackage, 'sum_ins', 0)
                                    ).format('0,0')}
                              </span>
                            </div>
                            <div className={classes.cardContentItem}>
                              <span className={classes.cardContentItemText}>
                                ซ่อม
                              </span>
                              <span className={classes.cardContentItemText}>
                                {_.get(currentOnePackage, 'garage', 'ไม่มี') ===
                                'DG'
                                  ? 'ซ่อมศูนย์'
                                  : _.get(currentOnePackage, 'garage', 'ไม่มี')}
                              </span>
                            </div>
                            {false && (
                              <div className={classes.cardContentItem}>
                                <span className={classes.cardContentItemText}>
                                  ค่าเสียหายส่วนแรก
                                </span>
                                <span className={classes.cardContentItemText}>
                                  {parseInt(
                                    _.get(currentOnePackage, 'disc_deduct', 0)
                                  ) === 0
                                    ? 'ไม่มี'
                                    : numeral(
                                        _.get(
                                          currentOnePackage,
                                          'disc_deduct',
                                          0
                                        )
                                      ).format('0,0')}
                                </span>
                              </div>
                            )}
                            {false && (
                              <div className={classes.cardContentItem}>
                                <span className={classes.cardContentItemText}>
                                  ค่ารักษาพยาบาล
                                </span>
                                <span className={classes.cardContentItemText}>
                                  {parseInt(
                                    _.get(currentOnePackage, 'medical', 0)
                                  ) === 0
                                    ? 'ไม่มี'
                                    : numeral(
                                        _.get(currentOnePackage, 'medical', 0)
                                      ).format('0,0')}
                                </span>
                              </div>
                            )}
                            <hr
                              className={classes.line}
                              style={{ margin: '16px 0' }}
                            />
                            <div className={classes.cardContentItem}>
                              <div
                                className={classes.cardContentItemTextContainer}
                              >
                                <CustomCheckbox
                                  style={{ float: 'left', width: '5%' }}
                                  checked={isAddActOne}
                                  onChange={event => {
                                    this.setState({
                                      isAddActOne: event.target.checked,
                                    })
                                  }}
                                  value="checkedB"
                                  color="#1a63b6"
                                />
                                <span className={classes.cardContentItemText}>
                                  + พรบ.รถยนต์
                                </span>
                                <div
                                  className={classes.button}
                                  onClick={() => {
                                    this.setState({
                                      modalVisible: true,
                                    })
                                  }}
                                >
                                  <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <ellipse
                                      cx="9"
                                      cy="9.04757"
                                      rx="9"
                                      ry="8.64986"
                                      fill="#333333"
                                    />
                                    <path
                                      d="M8.19141 10.6654H9.9375V12.3588H8.19141V10.6654ZM6.05273 6.44666C6.09961 5.33337 6.48828 4.54431 7.21875 4.07947C7.67969 3.78259 8.24609 3.63416 8.91797 3.63416C9.80078 3.63416 10.5332 3.84509 11.1152 4.26697C11.7012 4.68884 11.9941 5.31384 11.9941 6.14197C11.9941 6.64978 11.8672 7.07751 11.6133 7.42517C11.4648 7.63611 11.1797 7.90564 10.7578 8.23376L10.3418 8.55603C10.1152 8.73181 9.96484 8.93689 9.89062 9.17126C9.84375 9.3197 9.81836 9.55017 9.81445 9.86267H8.23242C8.25586 9.20251 8.31836 8.74744 8.41992 8.49744C8.52148 8.24353 8.7832 7.95251 9.20508 7.62439L9.63281 7.29041C9.77344 7.18494 9.88672 7.0697 9.97266 6.9447C10.1289 6.72986 10.207 6.49353 10.207 6.23572C10.207 5.93884 10.1191 5.66931 9.94336 5.42712C9.77148 5.18103 9.45508 5.05798 8.99414 5.05798C8.54102 5.05798 8.21875 5.20837 8.02734 5.50916C7.83984 5.80994 7.74609 6.12244 7.74609 6.44666H6.05273Z"
                                      fill="white"
                                    />
                                  </svg>
                                </div>
                              </div>
                              <span
                                className={classes.cardContentItemText}
                                style={{ fontFamily: 'San Francisco Text' }}
                              >
                                {parseInt(
                                  _.get(currentOnePackage, 'total_prem_comp', 0)
                                ) === 0
                                  ? 0
                                  : numeral(
                                      _.get(
                                        currentOnePackage,
                                        'total_prem_comp',
                                        0
                                      )
                                    ).format('0,0.00')}
                              </span>
                            </div>
                          </div>
                          <SubmitButton
                            onClick={this.goToNextPage(
                              'ประกันรถยนต์ชั้น 1',
                              currentOnePackage,
                              isAddActOne,
                              parseFloat(
                                _.get(
                                  currentOnePackage,
                                  'total_prem_vol',
                                  999999
                                )
                              ) +
                                (isAddActOne
                                  ? parseFloat(
                                      _.get(
                                        currentOnePackage,
                                        'total_prem_comp',
                                        0
                                      )
                                    )
                                  : 0) -
                                parseFloat(
                                  _.get(currentOnePackage, 'discount_price', 0)
                                )
                            )}
                          >
                            สนใจ
                          </SubmitButton>
                          <div
                            className={classes.expandButton}
                            onClick={() => {
                              this.setState({
                                isExpandOne: !isExpandOne,
                              })
                            }}
                          >
                            <span className={classes.expandText}>
                              รายละเอียดความคุ้มครอง
                            </span>
                            <Icon className={classes.expandIcon}>
                              {isExpandOne ? 'expand_less' : 'expand_more'}
                            </Icon>
                          </div>
                          {isExpandOne && (
                            <div className={classes.expandContent}>
                              <div className={classes.expandContentContainer}>
                                <br />
                                <div className={classes.cardContentItem}>
                                  <span className={classes.cardContentItemText}>
                                    ความรับผิดชอบต่อตัวรถยนต์
                                  </span>
                                </div>
                                <br />
                                <div className={classes.cardContentItem}>
                                  <span className={classes.cardContentItemText}>
                                    จากการชนกับพาหนะทางบก
                                  </span>
                                  <span
                                    className={
                                      classes.cardContentItemTextNumber
                                    }
                                  >
                                    {parseFloat(
                                      _.get(currentOnePackage, 'sum_ins', 0)
                                    ) === 0
                                      ? 0
                                      : numeral(
                                          _.get(currentOnePackage, 'sum_ins', 0)
                                        ).format('0,0')}
                                  </span>
                                </div>
                                <div className={classes.cardContentItem}>
                                  <span className={classes.cardContentItemText}>
                                    สูญหาย / ไฟไหม้
                                  </span>
                                  <span
                                    className={
                                      classes.cardContentItemTextNumber
                                    }
                                  >
                                    {parseInt(
                                      _.get(
                                        currentOnePackage,
                                        'sum_ins_fire_theft',
                                        0
                                      )
                                    ) === 0
                                      ? 0
                                      : numeral(
                                          _.get(
                                            currentOnePackage,
                                            'sum_ins_fire_theft',
                                            0
                                          )
                                        ).format('0,0')}
                                  </span>
                                </div>
                                {parseInt(
                                  _.get(
                                    currentOnePackage,
                                    'att_sumins.natural_sum_ins',
                                    0
                                  )
                                ) > 0 && (
                                  <div className={classes.cardContentItem}>
                                    <span
                                      className={classes.cardContentItemText}
                                    >
                                      น้ำท่วม ลูกเห็บ ฟ้าผ่า ลมพายุ
                                    </span>
                                    <span
                                      className={
                                        classes.cardContentItemTextNumber
                                      }
                                    >
                                      {parseInt(
                                        _.get(
                                          currentOnePackage,
                                          'att_sumins.natural_sum_ins',
                                          0
                                        )
                                      ) === 0
                                        ? 0
                                        : numeral(
                                            _.get(
                                              currentOnePackage,
                                              'att_sumins.natural_sum_ins',
                                              0
                                            )
                                          ).format('0,0')}
                                    </span>
                                  </div>
                                )}
                                <hr
                                  className={classes.line}
                                  style={{
                                    marginTop: '5px',
                                    marginBottom: '10px',
                                  }}
                                />
                                <div className={classes.cardContentItem}>
                                  <span className={classes.cardContentItemText}>
                                    ความรับผิดชอบต่อบุคคลภายนอก
                                  </span>
                                </div>
                                <br />
                                <div
                                  className={classes.cardContentItem}
                                  style={{ alignItems: 'flex-start' }}
                                >
                                  <span className={classes.cardContentItemText}>
                                    ความเสียหายต่อชีวิต ร่างกาย หรืออนามัย / คน
                                  </span>
                                  <span
                                    className={
                                      classes.cardContentItemTextNumber
                                    }
                                  >
                                    {parseInt(
                                      _.get(currentOnePackage, 'tpbi_per', 0)
                                    ) === 0
                                      ? 0
                                      : numeral(
                                          _.get(
                                            currentOnePackage,
                                            'tpbi_per',
                                            0
                                          )
                                        ).format('0,0')}
                                  </span>
                                </div>
                                <div
                                  className={classes.cardContentItem}
                                  style={{ alignItems: 'flex-start' }}
                                >
                                  <span className={classes.cardContentItemText}>
                                    ความเสียหายต่อชีวิต ร่างกาย หรืออนามัย /
                                    ครั้ง
                                  </span>
                                  <span
                                    className={
                                      classes.cardContentItemTextNumber
                                    }
                                  >
                                    {parseInt(
                                      _.get(currentOnePackage, 'tpbi_acc', 0)
                                    ) === 0
                                      ? 0
                                      : numeral(
                                          _.get(
                                            currentOnePackage,
                                            'tpbi_acc',
                                            0
                                          )
                                        ).format('0,0')}
                                  </span>
                                </div>
                                <div
                                  className={classes.cardContentItem}
                                  style={{ alignItems: 'flex-start' }}
                                >
                                  <span className={classes.cardContentItemText}>
                                    ความเสียหายต่อทรัพย์สิน/ครั้ง
                                  </span>
                                  <span
                                    className={
                                      classes.cardContentItemTextNumber
                                    }
                                  >
                                    {parseInt(
                                      _.get(currentOnePackage, 'tppd', 0)
                                    ) === 0
                                      ? 0
                                      : numeral(
                                          _.get(currentOnePackage, 'tppd', 0)
                                        ).format('0,0')}
                                  </span>
                                </div>
                                <hr
                                  className={classes.line}
                                  style={{
                                    marginTop: '5px',
                                    marginBottom: '10px',
                                  }}
                                />
                                <div className={classes.cardContentItem}>
                                  <span className={classes.cardContentItemText}>
                                    ความคุ้มครองตามเอกสารแนบท้าย
                                  </span>
                                </div>
                                <br />
                                <div
                                  className={classes.cardContentItem}
                                  style={{ alignItems: 'flex-start' }}
                                >
                                  <span className={classes.cardContentItemText}>
                                    อุบัติเหตุส่วนบุคคล/คน (รถยนต์ 6 คน, รถกระบะ
                                    2 คน)
                                  </span>
                                  <span
                                    className={
                                      classes.cardContentItemTextNumber
                                    }
                                  >
                                    {parseInt(
                                      _.get(currentOnePackage, 'c123_driver', 0)
                                    ) === 0
                                      ? 0
                                      : numeral(
                                          _.get(
                                            currentOnePackage,
                                            'c123_driver',
                                            0
                                          )
                                        ).format('0,0')}
                                  </span>
                                </div>
                                <div
                                  className={classes.cardContentItem}
                                  style={{ alignItems: 'flex-start' }}
                                >
                                  <span className={classes.cardContentItemText}>
                                    ค่ารักษาพยาบาล
                                  </span>
                                  <span
                                    className={
                                      classes.cardContentItemTextNumber
                                    }
                                  >
                                    {parseInt(
                                      _.get(currentOnePackage, 'medical', 0)
                                    ) === 0
                                      ? 0
                                      : numeral(
                                          _.get(currentOnePackage, 'medical', 0)
                                        ).format('0,0')}
                                  </span>
                                </div>
                                <div
                                  className={classes.cardContentItem}
                                  style={{ alignItems: 'flex-start' }}
                                >
                                  <span className={classes.cardContentItemText}>
                                    ค่าประกันตัวผู้ขับขี่ในคดีอาญา
                                  </span>
                                  <span
                                    className={
                                      classes.cardContentItemTextNumber
                                    }
                                  >
                                    {parseInt(
                                      _.get(currentOnePackage, 'bailbond', 0)
                                    ) === 0
                                      ? 0
                                      : numeral(
                                          _.get(
                                            currentOnePackage,
                                            'bailbond',
                                            0
                                          )
                                        ).format('0,0')}
                                  </span>
                                </div>
                                <br />
                                <div
                                  className={classes.cardContentItem}
                                  style={{ alignItems: 'flex-start' }}
                                >
                                  <span className={classes.cardContentItemText}>
                                    ภัยก่อการร้าย
                                  </span>
                                  <span
                                    className={
                                      classes.cardContentItemTextNumber
                                    }
                                  >
                                    {parseInt(
                                      _.get(currentOnePackage, 'sum_ins', 0)
                                    ) === 0
                                      ? 0
                                      : numeral(
                                          _.get(currentOnePackage, 'sum_ins', 0)
                                        ).format('0,0')}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {/*
                  //=================================== 
                  // ประกันชั้น 2+
                  //===================================*/}
                    {_.get(currentTwoplusPackage, 'total_prem_vol', null) && (
                      <div className={classes.cardContainer}>
                        <div className={classes.cardHeader}>
                          <p className={classes.cardTitle}>
                            ประกันรถยนต์ชั้น 2+
                          </p>
                          <p className={classes.cardPrice}>
                            ฿{' '}
                            <span className={classes.cardPriceNumber}>
                              {numeral(
                                parseFloat(
                                  _.get(
                                    currentTwoplusPackage,
                                    'total_prem_vol',
                                    999999
                                  )
                                ) +
                                  (isAddAct
                                    ? parseFloat(
                                        _.get(
                                          currentTwoplusPackage,
                                          'total_prem_comp',
                                          0
                                        )
                                      )
                                    : 0) -
                                  parseFloat(
                                    _.get(
                                      currentTwoplusPackage,
                                      'discount_price',
                                      0
                                    )
                                  )
                              ).format('0,0.00')}
                            </span>
                          </p>
                        </div>
                        <div className={classes.cardReturnContainer}>
                          <div className={classes.cardReturn}>
                            <span className={classes.cardReturnText}>
                              รับเงินคืน{' '}
                              <span className={classes.cardReturnTextNumber}>
                                {isAddAct
                                  ? numeral(
                                      parseFloat(
                                        _.get(
                                          currentTwoplusPackage,
                                          'cashback_value_cmi',
                                          0
                                        )
                                      ) +
                                        parseFloat(
                                          _.get(
                                            currentTwoplusPackage,
                                            'cashback_value',
                                            0
                                          )
                                        )
                                    ).format('0,0.00')
                                  : numeral(
                                      parseFloat(
                                        _.get(
                                          currentTwoplusPackage,
                                          'cashback_value',
                                          0
                                        )
                                      )
                                    ).format('0,0.00')}
                              </span>{' '}
                              บาท
                            </span>
                          </div>
                        </div>
                        <div className={classes.cardContentContainer}>
                          <div className={classes.cardContent}>
                            {minFilterPrice !== maxFilterPrice && (
                              <div className={classes.sliderContent}>
                                <span className={classes.slideTitle}>
                                  เลือกทุนประกันภัย
                                </span>
                                <br />
                                <br />
                                <br />
                                <div
                                  style={{
                                    marginLeft: '28px',
                                    marginRight: '28px',
                                  }}
                                >
                                  <CustomSlider
                                    styles={{
                                      thumb: {
                                        height: '20px',
                                        width: '20px',
                                        backgroundColor: '#118df5',
                                      },
                                    }}
                                    aria-label="Filter Price"
                                    aria-labelledby="discrete-slider-always"
                                    valueLabelFormat={() => {
                                      return numeral(filterPrice).format('0,0')
                                    }}
                                    valueLabelDisplay="on"
                                    min={minFilterPrice}
                                    max={maxFilterPrice}
                                    step={100000}
                                    value={filterPrice}
                                    onChange={this.changeSlide}
                                  />
                                </div>
                              </div>
                            )}
                            <div className={classes.cardContentItem}>
                              <span className={classes.cardContentItemText}>
                                ซ่อม
                              </span>
                              <span className={classes.cardContentItemText}>
                                {_.get(
                                  currentTwoplusPackage,
                                  'garage',
                                  'ไม่มี'
                                ) === 'DG'
                                  ? 'ซ่อมศูนย์'
                                  : _.get(
                                      currentTwoplusPackage,
                                      'garage',
                                      'ไม่มี'
                                    )}
                              </span>
                            </div>
                            <div className={classes.cardContentItem}>
                              <span className={classes.cardContentItemText}>
                                ค่าเสียหายส่วนแรก
                              </span>
                              <span className={classes.cardContentItemText}>
                                {parseInt(
                                  _.get(currentTwoplusPackage, 'disc_deduct', 0)
                                ) === 0
                                  ? 'ไม่มี'
                                  : numeral(
                                      _.get(
                                        currentTwoplusPackage,
                                        'disc_deduct',
                                        0
                                      )
                                    ).format('0,0')}
                              </span>
                            </div>
                            {false && (
                              <div className={classes.cardContentItem}>
                                <span className={classes.cardContentItemText}>
                                  ค่ารักษาพยาบาล
                                </span>
                                <span className={classes.cardContentItemText}>
                                  {parseInt(
                                    _.get(currentTwoplusPackage, 'medical', 0)
                                  ) === 0
                                    ? 'ไม่มี'
                                    : numeral(
                                        _.get(
                                          currentTwoplusPackage,
                                          'medical',
                                          0
                                        )
                                      ).format('0,0')}
                                </span>
                              </div>
                            )}
                            <hr
                              className={classes.line}
                              style={{ margin: '16px 0' }}
                            />
                            <div className={classes.cardContentItem}>
                              <div
                                className={classes.cardContentItemTextContainer}
                              >
                                <CustomCheckbox
                                  style={{ float: 'left', width: '5%' }}
                                  checked={isAddAct}
                                  onChange={event => {
                                    this.setState({
                                      isAddAct: event.target.checked,
                                    })
                                  }}
                                  value="checkedB"
                                  color="#1a63b6"
                                />
                                <span className={classes.cardContentItemText}>
                                  + พรบ.รถยนต์
                                </span>
                                <div
                                  className={classes.button}
                                  onClick={() => {
                                    this.setState({
                                      modalVisible: true,
                                    })
                                  }}
                                >
                                  <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <ellipse
                                      cx="9"
                                      cy="9.04757"
                                      rx="9"
                                      ry="8.64986"
                                      fill="#333333"
                                    />
                                    <path
                                      d="M8.19141 10.6654H9.9375V12.3588H8.19141V10.6654ZM6.05273 6.44666C6.09961 5.33337 6.48828 4.54431 7.21875 4.07947C7.67969 3.78259 8.24609 3.63416 8.91797 3.63416C9.80078 3.63416 10.5332 3.84509 11.1152 4.26697C11.7012 4.68884 11.9941 5.31384 11.9941 6.14197C11.9941 6.64978 11.8672 7.07751 11.6133 7.42517C11.4648 7.63611 11.1797 7.90564 10.7578 8.23376L10.3418 8.55603C10.1152 8.73181 9.96484 8.93689 9.89062 9.17126C9.84375 9.3197 9.81836 9.55017 9.81445 9.86267H8.23242C8.25586 9.20251 8.31836 8.74744 8.41992 8.49744C8.52148 8.24353 8.7832 7.95251 9.20508 7.62439L9.63281 7.29041C9.77344 7.18494 9.88672 7.0697 9.97266 6.9447C10.1289 6.72986 10.207 6.49353 10.207 6.23572C10.207 5.93884 10.1191 5.66931 9.94336 5.42712C9.77148 5.18103 9.45508 5.05798 8.99414 5.05798C8.54102 5.05798 8.21875 5.20837 8.02734 5.50916C7.83984 5.80994 7.74609 6.12244 7.74609 6.44666H6.05273Z"
                                      fill="white"
                                    />
                                  </svg>
                                </div>
                              </div>
                              <span
                                className={classes.cardContentItemText}
                                style={{ fontFamily: 'San Francisco Text' }}
                              >
                                {parseInt(
                                  _.get(
                                    currentTwoplusPackage,
                                    'total_prem_comp',
                                    0
                                  )
                                ) === 0
                                  ? 0
                                  : numeral(
                                      _.get(
                                        currentTwoplusPackage,
                                        'total_prem_comp',
                                        0
                                      )
                                    ).format('0,0.00')}
                              </span>
                            </div>
                          </div>
                          <SubmitButton
                            onClick={this.goToNextPage(
                              'ประกันรถยนต์ชั้น 2+',
                              currentTwoplusPackage,
                              isAddAct,
                              parseFloat(
                                _.get(
                                  currentTwoplusPackage,
                                  'total_prem_vol',
                                  999999
                                )
                              ) +
                                (isAddAct
                                  ? parseFloat(
                                      _.get(
                                        currentTwoplusPackage,
                                        'total_prem_comp',
                                        0
                                      )
                                    )
                                  : 0) -
                                parseFloat(
                                  _.get(
                                    currentTwoplusPackage,
                                    'discount_price',
                                    0
                                  )
                                )
                            )}
                          >
                            สนใจ
                          </SubmitButton>
                          <div
                            className={classes.expandButton}
                            onClick={() => {
                              this.setState({
                                isExpand: !isExpand,
                              })
                            }}
                          >
                            <span className={classes.expandText}>
                              รายละเอียดความคุ้มครอง
                            </span>
                            <Icon className={classes.expandIcon}>
                              {isExpand ? 'expand_less' : 'expand_more'}
                            </Icon>
                          </div>
                          {isExpand && (
                            <div className={classes.expandContent}>
                              <div className={classes.expandContentContainer}>
                                <br />
                                <div className={classes.cardContentItem}>
                                  <span className={classes.cardContentItemText}>
                                    ความรับผิดชอบต่อตัวรถยนต์
                                  </span>
                                </div>
                                <br />
                                <div className={classes.cardContentItem}>
                                  <span className={classes.cardContentItemText}>
                                    จากการชนกับพาหนะทางบก
                                  </span>
                                  <span
                                    className={
                                      classes.cardContentItemTextNumber
                                    }
                                  >
                                    {parseFloat(
                                      _.get(currentTwoplusPackage, 'sum_ins', 0)
                                    ) === 0
                                      ? 0
                                      : numeral(
                                          _.get(
                                            currentTwoplusPackage,
                                            'sum_ins',
                                            0
                                          )
                                        ).format('0,0')}
                                  </span>
                                </div>
                                <div className={classes.cardContentItem}>
                                  <span className={classes.cardContentItemText}>
                                    สูญหาย / ไฟไหม้
                                  </span>
                                  <span
                                    className={
                                      classes.cardContentItemTextNumber
                                    }
                                  >
                                    {parseInt(
                                      _.get(
                                        currentTwoplusPackage,
                                        'sum_ins_fire_theft',
                                        0
                                      )
                                    ) === 0
                                      ? 0
                                      : numeral(
                                          _.get(
                                            currentTwoplusPackage,
                                            'sum_ins_fire_theft',
                                            0
                                          )
                                        ).format('0,0')}
                                  </span>
                                </div>
                                <div className={classes.cardContentItem}>
                                  <span className={classes.cardContentItemText}>
                                    น้ำท่วม ลูกเห็บ ฟ้าผ่า ลมพายุ
                                  </span>
                                  <span
                                    className={
                                      classes.cardContentItemTextNumber
                                    }
                                  >
                                    {parseInt(
                                      _.get(
                                        currentTwoplusPackage,
                                        'att_sumins.natural_sum_ins',
                                        0
                                      )
                                    ) === 0
                                      ? 0
                                      : numeral(
                                          _.get(
                                            currentTwoplusPackage,
                                            'att_sumins.natural_sum_ins',
                                            0
                                          )
                                        ).format('0,0')}
                                  </span>
                                </div>
                                <hr
                                  className={classes.line}
                                  style={{
                                    marginTop: '5px',
                                    marginBottom: '10px',
                                  }}
                                />
                                <div className={classes.cardContentItem}>
                                  <span className={classes.cardContentItemText}>
                                    ความรับผิดชอบต่อบุคคลภายนอก
                                  </span>
                                </div>
                                <br />
                                <div
                                  className={classes.cardContentItem}
                                  style={{ alignItems: 'flex-start' }}
                                >
                                  <span className={classes.cardContentItemText}>
                                    ความเสียหายต่อชีวิต ร่างกาย หรืออนามัย / คน
                                  </span>
                                  <span
                                    className={
                                      classes.cardContentItemTextNumber
                                    }
                                  >
                                    {parseInt(
                                      _.get(
                                        currentTwoplusPackage,
                                        'tpbi_per',
                                        0
                                      )
                                    ) === 0
                                      ? 0
                                      : numeral(
                                          _.get(
                                            currentTwoplusPackage,
                                            'tpbi_per',
                                            0
                                          )
                                        ).format('0,0')}
                                  </span>
                                </div>
                                <div
                                  className={classes.cardContentItem}
                                  style={{ alignItems: 'flex-start' }}
                                >
                                  <span className={classes.cardContentItemText}>
                                    ความเสียหายต่อชีวิต ร่างกาย หรืออนามัย /
                                    ครั้ง
                                  </span>
                                  <span
                                    className={
                                      classes.cardContentItemTextNumber
                                    }
                                  >
                                    {parseInt(
                                      _.get(
                                        currentTwoplusPackage,
                                        'tpbi_acc',
                                        0
                                      )
                                    ) === 0
                                      ? 0
                                      : numeral(
                                          _.get(
                                            currentTwoplusPackage,
                                            'tpbi_acc',
                                            0
                                          )
                                        ).format('0,0')}
                                  </span>
                                </div>
                                <div
                                  className={classes.cardContentItem}
                                  style={{ alignItems: 'flex-start' }}
                                >
                                  <span className={classes.cardContentItemText}>
                                    ความเสียหายต่อทรัพย์สิน/ครั้ง
                                  </span>
                                  <span
                                    className={
                                      classes.cardContentItemTextNumber
                                    }
                                  >
                                    {parseInt(
                                      _.get(currentTwoplusPackage, 'tppd', 0)
                                    ) === 0
                                      ? 0
                                      : numeral(
                                          _.get(
                                            currentTwoplusPackage,
                                            'tppd',
                                            0
                                          )
                                        ).format('0,0')}
                                  </span>
                                </div>
                                <hr
                                  className={classes.line}
                                  style={{
                                    marginTop: '5px',
                                    marginBottom: '10px',
                                  }}
                                />
                                <div className={classes.cardContentItem}>
                                  <span className={classes.cardContentItemText}>
                                    ความคุ้มครองตามเอกสารแนบท้าย
                                  </span>
                                </div>
                                <br />
                                <div
                                  className={classes.cardContentItem}
                                  style={{ alignItems: 'flex-start' }}
                                >
                                  <span className={classes.cardContentItemText}>
                                    อุบัติเหตุส่วนบุคคล/คน (รถยนต์ 6 คน, รถกระบะ
                                    2 คน)
                                  </span>
                                  <span
                                    className={
                                      classes.cardContentItemTextNumber
                                    }
                                  >
                                    {parseInt(
                                      _.get(
                                        currentTwoplusPackage,
                                        'c123_driver',
                                        0
                                      )
                                    ) === 0
                                      ? 0
                                      : numeral(
                                          _.get(
                                            currentTwoplusPackage,
                                            'c123_driver',
                                            0
                                          )
                                        ).format('0,0')}
                                  </span>
                                </div>
                                <div
                                  className={classes.cardContentItem}
                                  style={{ alignItems: 'flex-start' }}
                                >
                                  <span className={classes.cardContentItemText}>
                                    ค่ารักษาพยาบาล
                                  </span>
                                  <span
                                    className={
                                      classes.cardContentItemTextNumber
                                    }
                                  >
                                    {parseInt(
                                      _.get(currentTwoplusPackage, 'medical', 0)
                                    ) === 0
                                      ? 0
                                      : numeral(
                                          _.get(
                                            currentTwoplusPackage,
                                            'medical',
                                            0
                                          )
                                        ).format('0,0')}
                                  </span>
                                </div>
                                <div
                                  className={classes.cardContentItem}
                                  style={{ alignItems: 'flex-start' }}
                                >
                                  <span className={classes.cardContentItemText}>
                                    ค่าประกันตัวผู้ขับขี่ในคดีอาญา
                                  </span>
                                  <span
                                    className={
                                      classes.cardContentItemTextNumber
                                    }
                                  >
                                    {parseInt(
                                      _.get(
                                        currentTwoplusPackage,
                                        'bailbond',
                                        0
                                      )
                                    ) === 0
                                      ? 0
                                      : numeral(
                                          _.get(
                                            currentTwoplusPackage,
                                            'bailbond',
                                            0
                                          )
                                        ).format('0,0')}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {/*
                  //=================================== 
                  // ประกันชั้น 3
                  //===================================*/}
                    {_.get(currentThreePackage, 'total_prem_vol', null) && (
                      <div className={classes.cardContainer}>
                        <div className={classes.cardHeader}>
                          <p className={classes.cardTitle}>
                            ประกันรถยนต์ชั้น 3
                          </p>
                          <p className={classes.cardPrice}>
                            ฿{' '}
                            <span className={classes.cardPriceNumber}>
                              {' '}
                              {numeral(
                                parseFloat(
                                  _.get(
                                    currentThreePackage,
                                    'total_prem_vol',
                                    999999
                                  )
                                ) +
                                  (isAddActThird
                                    ? parseFloat(
                                        _.get(
                                          currentThreePackage,
                                          'total_prem_comp',
                                          0
                                        )
                                      )
                                    : 0)
                              ).format('0,0.00')}
                            </span>
                          </p>
                        </div>
                        <div className={classes.cardReturnContainer}>
                          <div className={classes.cardReturn}>
                            <span className={classes.cardReturnText}>
                              รับเงินคืน{' '}
                              <span className={classes.cardReturnTextNumber}>
                                {isAddActThird
                                  ? numeral(
                                      parseFloat(
                                        _.get(
                                          currentThreePackage,
                                          'cashback_value',
                                          0
                                        )
                                      ) +
                                        parseFloat(
                                          _.get(
                                            currentThreePackage,
                                            'cashback_value_cmi',
                                            0
                                          )
                                        )
                                    ).format('0,0.00')
                                  : numeral(
                                      parseFloat(
                                        _.get(
                                          currentThreePackage,
                                          'cashback_value',
                                          0
                                        )
                                      )
                                    ).format('0,0.00')}
                              </span>{' '}
                              บาท
                            </span>
                          </div>
                        </div>
                        <div className={classes.cardContentContainer}>
                          <div className={classes.cardContent}>
                            {false && (
                              <div className={classes.cardContentItem}>
                                <span className={classes.cardContentItemText}>
                                  ทุนประกัน
                                </span>
                                <span className={classes.cardContentItemText}>
                                  ไม่มี
                                </span>
                              </div>
                            )}
                            <div className={classes.cardContentItem}>
                              <span className={classes.cardContentItemText}>
                                ซ่อม
                              </span>
                              <span className={classes.cardContentItemText}>
                                {_.get(
                                  currentThreePackage,
                                  'garage',
                                  'ไม่มี'
                                ) === 'DG'
                                  ? 'ซ่อมศูนย์'
                                  : _.get(
                                      currentThreePackage,
                                      'garage',
                                      'ไม่มี'
                                    )}
                              </span>
                            </div>
                            <div className={classes.cardContentItem}>
                              <span className={classes.cardContentItemText}>
                                ค่าเสียหายส่วนแรก
                              </span>
                              <span className={classes.cardContentItemText}>
                                {parseInt(
                                  _.get(currentThreePackage, 'disc_deduct', 0)
                                ) === 0
                                  ? 'ไม่มี'
                                  : numeral(
                                      _.get(
                                        currentThreePackage,
                                        'disc_deduct',
                                        0
                                      )
                                    ).format('0,0')}
                              </span>
                            </div>
                            {false && (
                              <div className={classes.cardContentItem}>
                                <span className={classes.cardContentItemText}>
                                  ค่ารักษาพยาบาล
                                </span>
                                <span className={classes.cardContentItemText}>
                                  {parseInt(
                                    _.get(currentThreePackage, 'medical', 0)
                                  ) === 0
                                    ? 'ไม่มี'
                                    : numeral(
                                        _.get(currentThreePackage, 'medical', 0)
                                      ).format('0,0')}
                                </span>
                              </div>
                            )}
                            <hr
                              className={classes.line}
                              style={{ margin: '16px 0' }}
                            />
                            <div className={classes.cardContentItem}>
                              <div
                                className={classes.cardContentItemTextContainer}
                              >
                                <CustomCheckbox
                                  style={{ float: 'left', width: '5%' }}
                                  checked={isAddActThird}
                                  onChange={event => {
                                    this.setState({
                                      isAddActThird: event.target.checked,
                                    })
                                  }}
                                  value="checkedB"
                                  color="#1a63b6"
                                />
                                <span className={classes.cardContentItemText}>
                                  + พรบ.รถยนต์
                                </span>
                                <div
                                  className={classes.button}
                                  onClick={() => {
                                    this.setState({
                                      modalVisible: true,
                                    })
                                  }}
                                >
                                  <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <ellipse
                                      cx="9"
                                      cy="9.04757"
                                      rx="9"
                                      ry="8.64986"
                                      fill="#333333"
                                    />
                                    <path
                                      d="M8.19141 10.6654H9.9375V12.3588H8.19141V10.6654ZM6.05273 6.44666C6.09961 5.33337 6.48828 4.54431 7.21875 4.07947C7.67969 3.78259 8.24609 3.63416 8.91797 3.63416C9.80078 3.63416 10.5332 3.84509 11.1152 4.26697C11.7012 4.68884 11.9941 5.31384 11.9941 6.14197C11.9941 6.64978 11.8672 7.07751 11.6133 7.42517C11.4648 7.63611 11.1797 7.90564 10.7578 8.23376L10.3418 8.55603C10.1152 8.73181 9.96484 8.93689 9.89062 9.17126C9.84375 9.3197 9.81836 9.55017 9.81445 9.86267H8.23242C8.25586 9.20251 8.31836 8.74744 8.41992 8.49744C8.52148 8.24353 8.7832 7.95251 9.20508 7.62439L9.63281 7.29041C9.77344 7.18494 9.88672 7.0697 9.97266 6.9447C10.1289 6.72986 10.207 6.49353 10.207 6.23572C10.207 5.93884 10.1191 5.66931 9.94336 5.42712C9.77148 5.18103 9.45508 5.05798 8.99414 5.05798C8.54102 5.05798 8.21875 5.20837 8.02734 5.50916C7.83984 5.80994 7.74609 6.12244 7.74609 6.44666H6.05273Z"
                                      fill="white"
                                    />
                                  </svg>
                                </div>
                              </div>
                              <span
                                className={classes.cardContentItemText}
                                style={{ fontFamily: 'San Francisco Text' }}
                              >
                                {parseInt(
                                  _.get(
                                    currentThreePackage,
                                    'total_prem_comp',
                                    0
                                  )
                                ) === 0
                                  ? 0
                                  : numeral(
                                      _.get(
                                        currentThreePackage,
                                        'total_prem_comp',
                                        0
                                      )
                                    ).format('0,0.00')}
                              </span>
                            </div>
                          </div>
                          <SubmitButton
                            onClick={this.goToNextPage(
                              'ประกันรถยนต์ชั้น 3',
                              currentThreePackage,
                              isAddActThird,
                              parseFloat(
                                _.get(
                                  currentThreePackage,
                                  'total_prem_vol',
                                  999999
                                )
                              ) +
                                (isAddActThird
                                  ? parseFloat(
                                      _.get(
                                        currentThreePackage,
                                        'total_prem_comp',
                                        0
                                      )
                                    )
                                  : 0) -
                                parseFloat(
                                  _.get(
                                    currentThreePackage,
                                    'discount_price',
                                    0
                                  )
                                )
                            )}
                          >
                            สนใจ
                          </SubmitButton>
                          <div
                            className={classes.expandButton}
                            onClick={() => {
                              this.setState({
                                isExpandThird: !isExpandThird,
                              })
                            }}
                          >
                            <span className={classes.expandText}>
                              รายละเอียดความคุ้มครอง
                            </span>
                            <Icon className={classes.expandIcon}>
                              {isExpandThird ? 'expand_less' : 'expand_more'}
                            </Icon>
                          </div>
                          {isExpandThird && (
                            <div className={classes.expandContent}>
                              <div className={classes.expandContentContainer}>
                                <br />
                                <div className={classes.cardContentItem}>
                                  <span className={classes.cardContentItemText}>
                                    ความรับผิดชอบต่อตัวรถยนต์
                                  </span>
                                </div>
                                <br />
                                <div className={classes.cardContentItem}>
                                  <span className={classes.cardContentItemText}>
                                    ไม่มี
                                  </span>
                                  <span />
                                </div>
                                <hr
                                  className={classes.line}
                                  style={{
                                    marginTop: '5px',
                                    marginBottom: '10px',
                                  }}
                                />
                                <div className={classes.cardContentItem}>
                                  <span className={classes.cardContentItemText}>
                                    ความรับผิดชอบต่อบุคคลภายนอก
                                  </span>
                                </div>
                                <br />
                                <div
                                  className={classes.cardContentItem}
                                  style={{ alignItems: 'flex-start' }}
                                >
                                  <span className={classes.cardContentItemText}>
                                    ความเสียหายต่อชีวิต ร่างกาย หรืออนามัย / คน
                                  </span>
                                  <span
                                    className={
                                      classes.cardContentItemTextNumber
                                    }
                                  >
                                    {parseInt(
                                      _.get(currentThreePackage, 'tpbi_per', 0)
                                    ) === 0
                                      ? 0
                                      : numeral(
                                          _.get(
                                            currentThreePackage,
                                            'tpbi_per',
                                            0
                                          )
                                        ).format('0,0')}
                                  </span>
                                </div>
                                <div
                                  className={classes.cardContentItem}
                                  style={{ alignItems: 'flex-start' }}
                                >
                                  <span className={classes.cardContentItemText}>
                                    ความเสียหายต่อชีวิต ร่างกาย หรืออนามัย /
                                    ครั้ง
                                  </span>
                                  <span
                                    className={
                                      classes.cardContentItemTextNumber
                                    }
                                  >
                                    {parseInt(
                                      _.get(currentThreePackage, 'tpbi_acc', 0)
                                    ) === 0
                                      ? 0
                                      : numeral(
                                          _.get(
                                            currentThreePackage,
                                            'tpbi_acc',
                                            0
                                          )
                                        ).format('0,0')}
                                  </span>
                                </div>
                                <div
                                  className={classes.cardContentItem}
                                  style={{ alignItems: 'flex-start' }}
                                >
                                  <span className={classes.cardContentItemText}>
                                    ความเสียหายต่อทรัพย์สิน/ครั้ง
                                  </span>
                                  <span
                                    className={
                                      classes.cardContentItemTextNumber
                                    }
                                  >
                                    {parseInt(
                                      _.get(currentThreePackage, 'tppd', 0)
                                    ) === 0
                                      ? 0
                                      : numeral(
                                          _.get(currentThreePackage, 'tppd', 0)
                                        ).format('0,0')}
                                  </span>
                                </div>
                                <hr
                                  className={classes.line}
                                  style={{
                                    marginTop: '5px',
                                    marginBottom: '10px',
                                  }}
                                />
                                <div className={classes.cardContentItem}>
                                  <span className={classes.cardContentItemText}>
                                    ความคุ้มครองตามเอกสารแนบท้าย
                                  </span>
                                </div>
                                <br />
                                <div
                                  className={classes.cardContentItem}
                                  style={{ alignItems: 'flex-start' }}
                                >
                                  <span className={classes.cardContentItemText}>
                                    อุบัติเหตุส่วนบุคคล/คน (รถยนต์ 7 คน)
                                  </span>
                                  <span
                                    className={
                                      classes.cardContentItemTextNumber
                                    }
                                  >
                                    {parseInt(
                                      _.get(
                                        currentThreePackage,
                                        'c123_driver',
                                        0
                                      )
                                    ) === 0
                                      ? 0
                                      : numeral(
                                          _.get(
                                            currentThreePackage,
                                            'c123_driver',
                                            0
                                          )
                                        ).format('0,0')}
                                  </span>
                                </div>
                                <div
                                  className={classes.cardContentItem}
                                  style={{ alignItems: 'flex-start' }}
                                >
                                  <span className={classes.cardContentItemText}>
                                    ค่ารักษาพยาบาล
                                  </span>
                                  <span
                                    className={
                                      classes.cardContentItemTextNumber
                                    }
                                  >
                                    {parseInt(
                                      _.get(currentThreePackage, 'medical', 0)
                                    ) === 0
                                      ? 0
                                      : numeral(
                                          _.get(
                                            currentThreePackage,
                                            'medical',
                                            0
                                          )
                                        ).format('0,0')}
                                  </span>
                                </div>
                                <div
                                  className={classes.cardContentItem}
                                  style={{ alignItems: 'flex-start' }}
                                >
                                  <span className={classes.cardContentItemText}>
                                    ค่าประกันตัวผู้ขับขี่ในคดีอาญา
                                  </span>
                                  <span
                                    className={
                                      classes.cardContentItemTextNumber
                                    }
                                  >
                                    {parseInt(
                                      _.get(currentThreePackage, 'bailbond', 0)
                                    ) === 0
                                      ? 0
                                      : numeral(
                                          _.get(
                                            currentThreePackage,
                                            'bailbond',
                                            0
                                          )
                                        ).format('0,0')}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {/*
                  //=================================== 
                  // CMI
                  //===================================*/}
                    {currentProduct === 'C' &&
                      packagesCMI.length > 0 &&
                      packagesCMI
                        .sort((a, b) => {
                          return (
                            parseFloat(_.get(a, 'total_prem', 999999)) -
                            parseFloat(_.get(b, 'total_prem', 999999))
                          )
                        })
                        .map(CMI => {
                          let jsonDetail = {}
                          try {
                            jsonDetail = JSON.parse(
                              _.get(CMI, 'packages_more_details', '')
                            )
                          } catch {}
                          let seatMessage
                          let cmiCarType
                          let carTypeName
                          switch (jsonDetail.use_no) {
                            case '1.10':
                              seatMessage = 'ประเภทรถยนต์ไม่เกิน 7 ที่นั่ง'
                              cmiCarType = 10
                              carTypeName = 'รถยนต์'
                              break
                            case '1.20a':
                              seatMessage =
                                'ประเภทรถตู้ส่วนบุคคลไม่เกิน 12 ที่นั่ง'
                              cmiCarType = 20
                              carTypeName = 'รถตู้'
                              break
                            case '1.40a':
                              seatMessage =
                                'ประเภทรถกระบะบรรทุกส่วนบุคคลไม่เกิน 4 ที่นั่ง'
                              cmiCarType = 40
                              carTypeName = 'รถกระบะ'
                              break
                            default:
                              seatMessage = ''
                              cmiCarType = 0
                              break
                          }
                          return (
                            <div className={classes.cardContainer}>
                              <div className={classes.cardHeader}>
                                <p className={classes.cardTitle}>
                                  พ.ร.บ. {carTypeName}
                                </p>
                                <p className={classes.cardPrice}>
                                  ฿{' '}
                                  <span className={classes.cardPriceNumber}>
                                    {numeral(
                                      parseFloat(
                                        _.get(CMI, 'total_prem', 999999)
                                      )
                                    ).format('0,0.00')}
                                  </span>
                                </p>
                              </div>
                              <div className={classes.cardReturnContainer}>
                                <div className={classes.cardReturn}>
                                  <span className={classes.cardReturnText}>
                                    รับเงินคืน{' '}
                                    <span
                                      className={classes.cardReturnTextNumber}
                                    >
                                      {numeral(
                                        parseFloat(
                                          _.get(CMI, 'cashback_value', 0)
                                        )
                                      ).format('0,0.00')}
                                    </span>{' '}
                                    บาท
                                  </span>
                                </div>
                              </div>
                              <div style={{ textAlign: 'center' }}>
                                <span className={classes.CMITitle}>
                                  ประกันรถยนต์ส่วนบุคคล
                                </span>
                                <span className={classes.CMIDesc}>
                                  {seatMessage}
                                </span>
                              </div>
                              <div className={classes.cardContentContainer}>
                                <div className={classes.cardContent} />
                                <SubmitButton
                                  onClick={this.goToNextPage(
                                    'พ.ร.บ. ' + carTypeName,
                                    CMI,
                                    false,
                                    parseFloat(
                                      _.get(CMI, 'total_prem', 999999)
                                    ) -
                                      parseFloat(
                                        _.get(CMI, 'discount_price', 0)
                                      ),
                                    cmiCarType
                                  )}
                                >
                                  สนใจ
                                </SubmitButton>
                                <div
                                  className={classes.expandButton}
                                  onClick={() => {
                                    this.setState({
                                      CMIexpand: {
                                        ...CMIexpand,
                                        [CMI.ID]: !CMIexpand[CMI.ID],
                                      },
                                    })
                                  }}
                                >
                                  <span className={classes.expandText}>
                                    รายละเอียดความคุ้มครอง
                                  </span>
                                  <Icon className={classes.expandIcon}>
                                    {isExpand ? 'expand_less' : 'expand_more'}
                                  </Icon>
                                </div>
                                {!!CMIexpand[CMI.ID] && (
                                  <div className={classes.expandContent}>
                                    <div
                                      className={classes.expandContentContainer}
                                    >
                                      <div
                                        className={classes.cardContentItem}
                                        style={{ alignItems: 'flex-start' }}
                                      >
                                        <span
                                          className={
                                            classes.cardContentItemText
                                          }
                                        >
                                          ความคุ้มครองส่วนแรก
                                        </span>
                                      </div>
                                      <div
                                        className={classes.cardContentItem}
                                        style={{ alignItems: 'flex-start' }}
                                      >
                                        <span
                                          className={
                                            classes.cardContentItemText
                                          }
                                        >
                                          ค่ารักษาพยาบาล จากการบาดเจ็บ (ตามจริง)
                                        </span>
                                        <span
                                          className={
                                            classes.cardContentItemTextNumber
                                          }
                                        >
                                          ไม่เกิน 30,000
                                        </span>
                                      </div>
                                      <div
                                        className={classes.cardContentItem}
                                        style={{ alignItems: 'flex-start' }}
                                      >
                                        <span
                                          className={
                                            classes.cardContentItemText
                                          }
                                        >
                                          การเสียชีวิต สูญเสียอวัยวะ หรือ
                                          ทุพพลภาพอย่างถาวร
                                        </span>
                                        <span
                                          className={
                                            classes.cardContentItemTextNumber
                                          }
                                        >
                                          35,000
                                        </span>
                                      </div>
                                      <hr className={classes.line} />
                                      <div
                                        className={classes.cardContentItem}
                                        style={{ alignItems: 'flex-start' }}
                                      >
                                        <span
                                          className={
                                            classes.cardContentItemText
                                          }
                                        >
                                          ความคุ้มครองส่วนสอง**
                                        </span>
                                      </div>
                                      <div
                                        className={classes.cardContentItem}
                                        style={{ alignItems: 'flex-start' }}
                                      >
                                        <span
                                          className={
                                            classes.cardContentItemText
                                          }
                                        >
                                          ค่ารักษาพยาบาล จากการบาดเจ็บ (ตามจริง)
                                        </span>
                                        <span
                                          className={
                                            classes.cardContentItemTextNumber
                                          }
                                        >
                                          ไม่เกิน 80,000
                                        </span>
                                      </div>
                                      <br />
                                      <div
                                        className={classes.cardContentItem}
                                        style={{ alignItems: 'flex-start' }}
                                      >
                                        <span
                                          className={
                                            classes.cardContentItemText
                                          }
                                        >
                                          การเสียชีวิต หรือทุพพลภาพ อย่างถาวร
                                        </span>
                                      </div>
                                      <div
                                        className={classes.cardContentItem}
                                        style={{ alignItems: 'flex-start' }}
                                      >
                                        <span
                                          className={
                                            classes.cardContentItemText
                                          }
                                        >
                                          * สูญเสียอวัยวะ
                                        </span>
                                        <span
                                          className={
                                            classes.cardContentItemTextNumber
                                          }
                                        >
                                          300,000
                                        </span>
                                      </div>
                                      <div
                                        className={classes.cardContentItem}
                                        style={{ alignItems: 'flex-start' }}
                                      >
                                        <span
                                          className={
                                            classes.cardContentItemText
                                          }
                                        >
                                          * นิ้วขาด 1 ข้อขึ้นไป
                                        </span>
                                        <span
                                          className={
                                            classes.cardContentItemTextNumber
                                          }
                                        >
                                          200,000
                                        </span>
                                      </div>
                                      <div
                                        className={classes.cardContentItem}
                                        style={{ alignItems: 'flex-start' }}
                                      >
                                        <span
                                          className={
                                            classes.cardContentItemText
                                          }
                                        >
                                          * สูญเสียอวัยวะ 1 ส่วน
                                        </span>
                                        <span
                                          className={
                                            classes.cardContentItemTextNumber
                                          }
                                        >
                                          250,000
                                        </span>
                                      </div>
                                      <div
                                        className={classes.cardContentItem}
                                        style={{ alignItems: 'flex-start' }}
                                      >
                                        <span
                                          className={
                                            classes.cardContentItemText
                                          }
                                        >
                                          * สูญเสียอวัยวะ 2 ส่วน
                                        </span>
                                        <span
                                          className={
                                            classes.cardContentItemTextNumber
                                          }
                                        >
                                          300,000
                                        </span>
                                      </div>
                                      <br />
                                      <div
                                        className={classes.cardContentItem}
                                        style={{ alignItems: 'flex-start' }}
                                      >
                                        <span
                                          className={
                                            classes.cardContentItemText
                                          }
                                        >
                                          ชดเชยรายวัน 200 บาท รวมกัน ไม่เกิน 20
                                          วัน กรณีเข้าพักรักษาพยาบาล
                                          ในสถานพยาบาลในฐานะ คนไข้ใน
                                        </span>
                                        <span
                                          className={
                                            classes.cardContentItemTextNumber
                                          }
                                        >
                                          สูงสุดไม่เกิน 4,000
                                        </span>
                                      </div>
                                      <hr className={classes.line} />
                                      <div>
                                        <span
                                          style={{
                                            ...fontDefault,
                                            fontSize: '12px',
                                            color: '#878b96',
                                            display: 'block',
                                          }}
                                        >
                                          *จำนวนเงินค่าเสียหายเบื้องต้น
                                          ได้รับโดยไม่ต้องรอพิสูจน์ ความผิด
                                        </span>
                                        <span
                                          style={{
                                            ...fontDefault,
                                            fontSize: '12px',
                                            color: '#878b96',
                                            display: 'block',
                                          }}
                                        >
                                          **จำนวนเงินค่าเสียหายส่วนที่เกินกว่าค่าเสียหายเบื้องต้น
                                          ผู้ประสบภัยจะได้รับภายหลังจากการพิสูจน์แล้วว่าไม่ได้เป็นฝ่าย
                                          ที่จะต้องรับผิดตามกฎหมาย
                                          โดยมีวงเงินคุ้มครองรวมกับ
                                          ค่าเสียหายเบื้องต้นดังนี้
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          )
                        })}
                  </Grid>
                </Grid>
              </Container>
            </div>
          )}
          <Modal
            styles={{ outline: 0 }}
            open={modalVisible}
            onClose={() => {
              this.setState({ modalVisible: false })
            }}
          >
            <div className={classes.modalContainer}>
              <IconButton
                className={classes.modalClose}
                onClick={() => {
                  this.setState({ modalVisible: false })
                }}
              >
                <Icon style={{ fontSize: '18px', color: '#4A4A4A' }}>
                  close
                </Icon>
              </IconButton>
              <div
                className={classes.cardHeader}
                style={{ height: '57px', textAlign: 'left', padding: '16px' }}
              >
                <p className={classes.cardTitle}>พรบ. รถยนต์</p>
              </div>
              <div className={classes.modalContent}>
                <div
                  className={classes.cardContentItem}
                  style={{ alignItems: 'flex-start' }}
                >
                  <span className={classes.cardContentItemText}>
                    ความคุ้มครองส่วนแรก
                  </span>
                </div>
                <div
                  className={classes.cardContentItem}
                  style={{ alignItems: 'flex-start' }}
                >
                  <span className={classes.cardContentItemText}>
                    จำนวนเงินค่าเสียหายเบื้องต้น
                    ได้รับโดยไม่ต้องรอพิสูจน์ความผิด
                  </span>
                </div>
                <br />
                <div
                  className={classes.cardContentItem}
                  style={{ alignItems: 'flex-start' }}
                >
                  <span className={classes.cardContentItemText}>
                    ค่ารักษาพยาบาล จากการบาดเจ็บ (ตามจริง)
                  </span>
                  <span className={classes.cardContentItemTextNumber}>
                    ไม่เกิน 30,000
                  </span>
                </div>
                <div
                  className={classes.cardContentItem}
                  style={{ alignItems: 'flex-start' }}
                >
                  <span className={classes.cardContentItemText}>
                    การเสียชีวิต สูญเสียอวัยวะ หรือทุพพลภาพอย่างถาวร
                  </span>
                  <span className={classes.cardContentItemTextNumber}>
                    35,000
                  </span>
                </div>

                <hr className={classes.line} />
                <div
                  className={classes.cardContentItem}
                  style={{ alignItems: 'flex-start' }}
                >
                  <span className={classes.cardContentItemText}>
                    ความคุ้มครองส่วนสอง
                  </span>
                </div>
                <div
                  className={classes.cardContentItem}
                  style={{ alignItems: 'flex-start' }}
                >
                  <span className={classes.cardContentItemText}>
                    จำนวนเงินค่าเสียหายส่วนที่เกินกว่าค่าเสียหายเบื้องต้น
                    ผู้ประสบภัยจะได้รับภายหลังจากการพิสูจน์แล้วว่าไม่ได้เป็นฝ่ายที่จะต้องรับผิดตามกฎหมาย
                    โดยมีวงเงินคุ้มครองรวมกับค่าเสียหายเบื้องต้นดังนี้
                  </span>
                </div>
                <br />
                <div
                  className={classes.cardContentItem}
                  style={{ alignItems: 'flex-start' }}
                >
                  <span className={classes.cardContentItemText}>
                    ค่ารักษาพยาบาล จากการบาดเจ็บ (ตามจริง)
                  </span>
                  <span className={classes.cardContentItemTextNumber}>
                    ไม่เกิน 80,000
                  </span>
                </div>
                <br />
                <div
                  className={classes.cardContentItem}
                  style={{ alignItems: 'flex-start' }}
                >
                  <span className={classes.cardContentItemText}>
                    การเสียชีวิต หรือทุพพลภาพ อย่างถาวร
                  </span>
                </div>
                <div
                  className={classes.cardContentItem}
                  style={{ alignItems: 'flex-start' }}
                >
                  <span className={classes.cardContentItemText}>
                    สูญเสียอวัยวะ
                  </span>
                </div>
                <div
                  className={classes.cardContentItem}
                  style={{ alignItems: 'flex-start' }}
                >
                  <span className={classes.cardContentItemText}>
                    - นิ้วขาด 1 ข้อขึ้นไป
                  </span>
                  <span className={classes.cardContentItemTextNumber}>
                    200,000
                  </span>
                </div>
                <div
                  className={classes.cardContentItem}
                  style={{ alignItems: 'flex-start' }}
                >
                  <span className={classes.cardContentItemText}>
                    - สูญเสียอวัยวะ 1 ส่วน
                  </span>
                  <span className={classes.cardContentItemTextNumber}>
                    250,000
                  </span>
                </div>
                <div
                  className={classes.cardContentItem}
                  style={{ alignItems: 'flex-start' }}
                >
                  <span className={classes.cardContentItemText}>
                    - สูญเสียอวัยวะ 2 ส่วน
                  </span>
                  <span className={classes.cardContentItemTextNumber}>
                    300,000
                  </span>
                </div>
                <div
                  className={classes.cardContentItem}
                  style={{ alignItems: 'flex-start' }}
                >
                  <span className={classes.cardContentItemText}>
                    ชดเชยรายวัน 200 บาท รวมกันไม่เกิน 20 วัน
                    กรณีเข้าพักรักษาพยาบาลในสถานพยาบาลในฐานะคนไข้ใน
                  </span>
                  <span className={classes.cardContentItemTextNumber}>
                    สูงสุดไม่เกิน 4,000
                  </span>
                </div>
              </div>
            </div>
          </Modal>
        </Body>
        <Header>
          <TopBar arrowLeft={this.backButton}>
            <Typography align="center" className={classes.textTopBar}>
              แผนประกันภัย
            </Typography>
          </TopBar>
        </Header>
      </Wrapper>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    packages: _.get(state, 'motor.packages', []),
    packagesCMI: _.get(state, 'motor.packagesCMI', []),
    isPackageLoading: _.get(state, 'motor.isPackageLoading', false),
    isPackageCMILoading: _.get(state, 'motor.isPackageCMILoading', false),
    currentProduct: 'V',
    carBrand: _.get(state, 'motor.userInput.carBrand', ''),
    carModel: _.get(state, 'motor.userInput.carModel', ''),
    carYear: _.get(state, 'motor.userInput.carYear', ''),
    auth: _.get(state, 'auth', 0),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setUserInput: data => {
      dispatch({
        type: 'USER_INPUT',
        data,
      })
    },
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SelectPackage))
