import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Wrapper from '../../components/Wrapper'
import Header from '../../components/Header'
import Body from '../../components/Body'
import TopBar from '../../components/TopBar'
import Loading from '../../components/Loading'

import _ from 'lodash'
import { motorService } from '../../services/motor.service'

import Modal from '@material-ui/core/Modal'

const fontDefault = {
  fontFamily: 'thonburiregular',
  fontStyle: 'normal',
}

const styles = {
  textTopBar: {
    ...fontDefault,
    color: '#333333',
    fontSize: '18px',
    fontWeight: 'bold',
  },
  container: {
    backgroundColor: '#eff0f4',
  },
  list: {
    margin: '0',
    padding: '0',
    listStyle: 'none',
  },
  listItem: {
    paddingLeft: '16px',
  },
  listItemContent: {
    borderBottom: 'solid 1px #dee1e9',
    padding: '16px 0',
  },
  listItemText: {
    color: '#0e162c',
    ...fontDefault,
    fontFamily: 'San Francisco Text',
    fontSize: '16px',
  },
  fieldInput: {
    width: '100%',
    padding: '16px',
    ...fontDefault,
    fontSize: '16px',
    color: '#333333',
    borderRadius: '8px',
    border: 'none',
    '&:focus': {
      outlineWidth: '0',
    },
    '&::placeholder': {
      color: '#cdd2de',
    },
  },
  inputContainerInput: {
    border: '1px solid #ffffff',
    margin: '16px',
    borderRadius: '8px',
    boxShadow: '0 1px 4px 0 rgba(157, 157, 157, 0.3)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}

class BrandPicker extends Component {
  state = {
    filterText: '',
  }

  backButton = () => {
    const { onBack = () => {} } = this.props
    onBack()
    this.goToTop()
  }

  goNext = brand => () => {
    const { setUserInput, onBack = () => {} } = this.props
    setUserInput({
      carBrand: brand,
      carModel: null,
      carYear: null,
    })
    //this.getModelCar(brand)
    onBack()
    this.goToTop()
  }

  goToTop = () => {
    const presentation = document.querySelector('[role="presentation"]')
    if (presentation) {
      presentation.scrollTop = 0
    }
  }

  getModelCar = async brand => {
    const {
      cmiCarType,
      currentProduct,
      carModelLoading,
      carModelDone,
      auth,
    } = this.props
    const token = _.get(auth, 'token', null)
    try {
      carModelLoading()
      const allPromise = []
      const carFirst = motorService
        .getModelCar(token, {
          brand,
          prod_type: currentProduct,
          car_type: '1',
        })
        .then(respCars => {
          let cars = _.get(respCars, 'model_cars', [])
          cars.forEach(car => {
            car.carType = 1
          })
          return cars
        })
      const carThird = motorService
        .getModelCar(token, {
          brand,
          prod_type: currentProduct,
          car_type: '3',
        })
        .then(respCars => {
          let cars = _.get(respCars, 'model_cars', [])
          cars.forEach(car => {
            car.carType = 3
          })
          return cars
        })
      allPromise.push(carFirst, carThird)
      let data = await Promise.all(allPromise)
      const models = []
      data = _.concat(_.get(data, 0, []), _.get(data, 1, []))
      debugger
      data.forEach(model => {
        models.push({
          value: _.get(model, 'x_car_code', ''),
          label: _.get(model, 'x_car_model', ''),
          x_cc: _.get(model, 'x_cc', ''),
          carType: _.get(model, 'carType', 0),
        })
      })

      const { updateCarModels } = this.props
      updateCarModels(models)
      carModelDone()
    } catch (error) {
      //carModelDone()
    }
  }

  render() {
    const {
      classes,
      currentProduct,
      carBrandsV,
      carBrandsC,
      isCarBrandLoading,
      open,
    } = this.props
    let carBrands = []
    if (currentProduct === 'V') {
      carBrands = carBrandsV
    } else {
      carBrands = carBrandsC
    }
    const { filterText } = this.state
    const filteredCarBrands = carBrands.filter(p => {
      return p.toLowerCase().match(filterText.toLowerCase())
    })
    return (
      <Modal
        style={{
          outline: 0,
          overflow: 'auto',
          height: '100%',
          display: 'block',
        }}
        open={open}
      >
        <Wrapper>
          <Header>
            <TopBar arrowLeft={this.backButton}>
              <Typography align="center" className={classes.textTopBar}>
                เลือกยี่ห้อรถยนต์
              </Typography>
            </TopBar>
          </Header>

          <Body style={{ backgroundColor: '#eff0f4', overflow: 'auto' }}>
            {isCarBrandLoading ? (
              <Loading />
            ) : (
              <div className={classes.container}>
                <Grid container>
                  <Grid item xs={12}>
                    <div className={classes.inputContainerInput}>
                      <input
                        className={classes.fieldInput}
                        type="text"
                        placeholder="ค้นหา"
                        autoFocus
                        value={filterText}
                        onChange={event => {
                          this.setState({
                            filterText: event.target.value,
                          })
                        }}
                      />
                    </div>
                    <ul className={classes.list}>
                      {filteredCarBrands.map(brand => {
                        return (
                          <li
                            className={classes.listItem}
                            onClick={this.goNext(brand)}
                            key={brand}
                          >
                            <div className={classes.listItemContent}>
                              <span className={classes.listItemText}>
                                {brand}
                              </span>
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  </Grid>
                </Grid>
              </div>
            )}
          </Body>
        </Wrapper>
      </Modal>
    )
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    currentProduct: 'V',
    cmiCarType: _.get(state, 'motor.userInput.cmiCarType', 0),
    carBrandsV: _.get(state, 'motor.carBrandsV', []),
    carBrandsC: _.get(state, 'motor.carBrandsC', []),
    isCarBrandLoading: _.get(state, 'motor.isCarBrandLoading', false),
    auth: state.auth,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    carModelLoading: () => {
      dispatch({
        type: 'API_CAR_MODEL_LOADING',
      })
    },
    carModelDone: () => {
      dispatch({
        type: 'API_CAR_MODEL_DONE',
      })
    },
    updateCarModels: data => {
      dispatch({
        type: 'CAR_MODELS',
        data,
      })
    },
    setUserInput: data => {
      dispatch({
        type: 'USER_INPUT',
        data,
      })
    },
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BrandPicker))
