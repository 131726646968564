import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Wrapper from '../../components/Wrapper'
import Header from '../../components/Header'
import Body from '../../components/Body'
import TopBar from '../../components/TopBar'
import Loading from '../../components/Loading'

import _ from 'lodash'
import Modal from '@material-ui/core/Modal'

const fontDefault = {
  fontFamily: 'thonburiregular',
  fontStyle: 'normal',
}

const styles = {
  textTopBar: {
    ...fontDefault,
    color: '#333333',
    fontSize: '18px',
    fontWeight: 'bold',
  },
  container: {
    backgroundColor: '#eff0f4',
  },
  list: {
    margin: '0',
    padding: '0',
    listStyle: 'none',
  },
  listItem: {
    paddingLeft: '16px',
  },
  listItemContent: {
    borderBottom: 'solid 1px #dee1e9',
    padding: '16px 0',
  },
  listItemText: {
    color: '#0e162c',
    ...fontDefault,
    fontFamily: 'San Francisco Text',
    fontSize: '16px',
  },
  fieldInput: {
    width: '100%',
    padding: '16px',
    ...fontDefault,
    fontSize: '16px',
    color: '#333333',
    borderRadius: '8px',
    border: 'none',
    '&:focus': {
      outlineWidth: '0',
    },
    '&::placeholder': {
      color: '#cdd2de',
    },
  },
  inputContainerInput: {
    border: '1px solid #ffffff',
    margin: '16px',
    borderRadius: '8px',
    boxShadow: '0 1px 4px 0 rgba(157, 157, 157, 0.3)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}

class ModelPicker extends Component {
  state = {
    filterText: '',
  }

  backButton = () => {
    const { onBack = () => {} } = this.props
    onBack()
    this.goToTop()
  }

  goToTop = () => {
    const presentation = document.querySelector('[role="presentation"]')
    if (presentation) {
      presentation.scrollTop = 0
    }
  }

  goNext = model => () => {
    const { onBack = () => {}, setUserInput } = this.props
    setUserInput({
      carModel: model,
      carYear: null,
    })
    onBack()
    this.goToTop()
  }

  render() {
    const {
      classes,
      currentProduct,
      carBrand,
      carModelsV,
      carModelsC,
      isCarModelLoading,
      open,
      cmiCarType,
    } = this.props
    let carModels = []
    carModels = _.get(carModelsV, carBrand, [])
      .filter((thing, index, self) => {
        return index === self.findIndex(t => t.x_carname === thing.x_carname)
      })
      .map(model => {
        return {
          value: _.get(model, 'x_car_code', ''),
          label: _.get(model, 'x_carname', ''),
          x_cc: _.get(model, 'x_cc', ''),
          carType: _.get(model, 'x_cartype', 0),
        }
      })
    const { filterText } = this.state
    const filteredCarModels = carModels
      .filter(p => {
        return _.get(p, 'label', '')
          .toLowerCase()
          .match(filterText.toLowerCase())
      })
      .sort((a, b) => {
        if (_.get(a, 'label', '') < _.get(b, 'label', '')) {
          return -1
        }
        if (_.get(a, 'label', '') > _.get(b, 'label', '')) {
          return 1
        }
        return 0
      })
    return (
      <Modal
        style={{
          outline: 0,
          overflow: 'auto',
          height: '100%',
          display: 'block',
        }}
        open={open}
      >
        <Wrapper>
          <Header>
            <TopBar arrowLeft={this.backButton}>
              <Typography align="center" className={classes.textTopBar}>
                เลือกรุ่นรถยนต์
              </Typography>
            </TopBar>
          </Header>
          <Body style={{ backgroundColor: '#eff0f4', overflow: 'auto' }}>
            {isCarModelLoading ? (
              <Loading />
            ) : (
              <div className={classes.container}>
                <Grid container>
                  <Grid item xs={12}>
                    <div className={classes.inputContainerInput}>
                      <input
                        className={classes.fieldInput}
                        type="text"
                        placeholder="ค้นหา"
                        autoFocus
                        value={filterText}
                        onChange={event => {
                          this.setState({
                            filterText: event.target.value,
                          })
                        }}
                      />
                    </div>
                    <ul className={classes.list}>
                      {filteredCarModels.map((model, index) => {
                        return (
                          <li
                            className={classes.listItem}
                            onClick={this.goNext(model)}
                            key={_.get(model, 'label', '') + index}
                          >
                            <div className={classes.listItemContent}>
                              <span className={classes.listItemText}>
                                {_.get(model, 'label', '')}
                              </span>
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  </Grid>
                </Grid>
              </div>
            )}
          </Body>
        </Wrapper>
      </Modal>
    )
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    currentProduct: _.get(state, 'motor.userInput.currentProduct', ''),
    carBrand: _.get(state, 'motor.userInput.carBrand', ''),
    carModelsV: _.get(state, 'motor.carModelsV', {}),
    carModelsC: _.get(state, 'motor.carModelsC', {}),
    isCarModelLoading: _.get(state, 'motor.isCarModelLoading', false),
    cmiCarType: _.get(state, 'motor.userInput.cmiCarType', 0),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setUserInput: data => {
      dispatch({
        type: 'USER_INPUT',
        data,
      })
    },
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ModelPicker))
