import { motorConstants } from '../constants'

const initialState = {
  userInput: {
    currentProduct: null,
    carBrand: null,
    carModel: null,
    carYear: null,
    currentPackage: null,
    isVMIIncludeCMI: false,
    cmiCarType: 0,
    licensePlate: '',
    plateProvince: null,
    vehicleID: '',
    effDate: null,
    ID: '',
    gender: '',
    fullname: '',
    birthdate: null,
    phoneNumber: '',
    address: '',
    moreAddress: null,
    email: '',
    isAgree: false,
    orderId: null,
    OTPRef: null,
    selectPrice: 0,
    orderSuccessData: null,

  //  currentProduct: 'V',
  //  carBrand: null,
  //  carModel: null,
  //  carYear: null,
  //  currentPackage: null,
  //  isVMIIncludeCMI: false,
  //  licensePlate: 'as12312',
  //  plateProvince: null,
  //  vehicleID: 'AD1231231212312',
  //  ID: '1231231231231',
  //  gender: 'นาย',
  //  fullname: 'Tanapat Sainak',
  //  birthdate: '',
  //  phoneNumber: '0875487117',
  //  address: '72 24',
  //  moreAddress: null,
  //  email: 'fallen_things@hotmail.cpom',
  //  isAgree: true,
  },
  isCarBrandLoading: false,
  isCarModelLoading: false,
  isPackageLoading: false,
  isPackageCMILoading: false,
  carBrands: [],
  carModels: [],
  packages: [],
  packagesCMI: [],
}

export default function motorReducer(state = initialState, action = {}) {
  switch (action.type) {
    case 'USER_INPUT':
      const oldInput = state.userInput || {}
      return {
        ...state,
        userInput: {
          ...oldInput,
          ...action.data,
        },
      }
    case 'API_CAR_BRAND_LOADING':
      return {
        ...state,
        isCarBrandLoading: true,
      }
    case 'API_CAR_BRAND_DONE':
      return {
        ...state,
        isCarBrandLoading: false,
      }
    case 'API_CAR_MODEL_LOADING':
      return {
        ...state,
        isCarModelLoading: true,
      }
    case 'API_CAR_MODEL_DONE':
      return {
        ...state,
        isCarModelLoading: false,
      }
    case 'API_PACKAGE_LOADING':
      return {
        ...state,
        isPackageLoading: true,
      }
    case 'API_PACKAGE_DONE':
      return {
        ...state,
        isPackageLoading: false,
      }
    case 'API_PACKAGE_CMI_LOADING':
      return {
        ...state,
        isPackageCMILoading: true,
      }
    case 'API_PACKAGE_CMI_DONE':
      return {
        ...state,
        isPackageCMILoading: false,
      }
    case 'CAR_BRANDS':
      return {
        ...state,
        carBrandsV: action.dataV || [],
        carBrandsC: action.dataC || [],
      }
    case 'CAR_MODELS':
      return {
        ...state,
        carModelsV: action.dataV || [],
        carModelsC: action.dataC || [],
      }
    case 'PACKAGES':
      return {
        ...state,
        packages: action.data || [],
      }
    case 'PACKAGES_CMI':
      return {
        ...state,
        packagesCMI: action.data || [],
      }
    case motorConstants.SET_PACKAGE:
      return {
        ...state,
        packages: { ...action },
      }
    default:
      return state
  }
}
